/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindAllResidentProgressNotes200Response,
  FindAllResidentVitalsV2200Response,
  FindAllResidents200Response,
  FindAllResidentsWithExistingStatement200Response,
  FindAllResidentsWithNoBills200Response,
  Month,
  ResidentMoveOut,
} from '../models/index';
import {
    FindAllResidentProgressNotes200ResponseFromJSON,
    FindAllResidentProgressNotes200ResponseToJSON,
    FindAllResidentVitalsV2200ResponseFromJSON,
    FindAllResidentVitalsV2200ResponseToJSON,
    FindAllResidents200ResponseFromJSON,
    FindAllResidents200ResponseToJSON,
    FindAllResidentsWithExistingStatement200ResponseFromJSON,
    FindAllResidentsWithExistingStatement200ResponseToJSON,
    FindAllResidentsWithNoBills200ResponseFromJSON,
    FindAllResidentsWithNoBills200ResponseToJSON,
    MonthFromJSON,
    MonthToJSON,
    ResidentMoveOutFromJSON,
    ResidentMoveOutToJSON,
} from '../models/index';

export interface CancelMoveOutResidentRequest {
    id: string;
}

export interface FindAllResidentProgressNotesRequest {
    id: string;
    type?: string;
    q?: string;
    restricted?: string;
    startDateUtc?: string;
    endDateUtc?: string;
    limit?: string;
    page?: string;
}

export interface FindAllResidentVaccinesRequest {
    id: string;
    startDateUtc?: Date;
    endDateUtc?: Date;
}

export interface FindAllResidentVitalsV2Request {
    id: string;
    page: number;
    limit: number;
    vitalTypeName?: string;
}

export interface FindAllResidentsRequest {
    facilityId?: string;
    search?: string;
    limit?: number;
    page?: number;
    recentlyViewed?: boolean;
}

export interface FindAllResidentsWithExistingStatementRequest {
    facilityId: string;
}

export interface FindAllResidentsWithNoBillsRequest {
    facilityId: string;
    month: Month;
    year: number;
}

export interface MoveOutResidentRequest {
    id: string;
    residentMoveOut: ResidentMoveOut;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Cancel a resident move out
     */
    async cancelMoveOutResidentRaw(requestParameters: CancelMoveOutResidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelMoveOutResident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents/{id}/cancel-move-out`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel a resident move out
     */
    async cancelMoveOutResident(requestParameters: CancelMoveOutResidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelMoveOutResidentRaw(requestParameters, initOverrides);
    }

    /**
     * Get all resident progress notes
     */
    async findAllResidentProgressNotesRaw(requestParameters: FindAllResidentProgressNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentProgressNotes200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllResidentProgressNotes.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.restricted !== undefined) {
            queryParameters['restricted'] = requestParameters.restricted;
        }

        if (requestParameters.startDateUtc !== undefined) {
            queryParameters['start_date_utc'] = requestParameters.startDateUtc;
        }

        if (requestParameters.endDateUtc !== undefined) {
            queryParameters['end_date_utc'] = requestParameters.endDateUtc;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents/{id}/progress-notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentProgressNotes200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all resident progress notes
     */
    async findAllResidentProgressNotes(requestParameters: FindAllResidentProgressNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentProgressNotes200Response> {
        const response = await this.findAllResidentProgressNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all resident vaccines
     */
    async findAllResidentVaccinesRaw(requestParameters: FindAllResidentVaccinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllResidentVaccines.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDateUtc !== undefined) {
            queryParameters['start_date_utc'] = (requestParameters.startDateUtc as any).toISOString();
        }

        if (requestParameters.endDateUtc !== undefined) {
            queryParameters['end_date_utc'] = (requestParameters.endDateUtc as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents/{id}/resident-vaccines`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all resident vaccines
     */
    async findAllResidentVaccines(requestParameters: FindAllResidentVaccinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.findAllResidentVaccinesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get one resident vital
     */
    async findAllResidentVitalsV2Raw(requestParameters: FindAllResidentVitalsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentVitalsV2200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllResidentVitalsV2.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling findAllResidentVitalsV2.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling findAllResidentVitalsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.vitalTypeName !== undefined) {
            queryParameters['vitalTypeName'] = requestParameters.vitalTypeName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents/{id}/resident-vitals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentVitalsV2200ResponseFromJSON(jsonValue));
    }

    /**
     * Get one resident vital
     */
    async findAllResidentVitalsV2(requestParameters: FindAllResidentVitalsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentVitalsV2200Response> {
        const response = await this.findAllResidentVitalsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all residents
     */
    async findAllResidentsRaw(requestParameters: FindAllResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidents200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.recentlyViewed !== undefined) {
            queryParameters['recently_viewed'] = requestParameters.recentlyViewed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all residents
     */
    async findAllResidents(requestParameters: FindAllResidentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidents200Response> {
        const response = await this.findAllResidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all residents that have at least one statement record associated
     */
    async findAllResidentsWithExistingStatementRaw(requestParameters: FindAllResidentsWithExistingStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentsWithExistingStatement200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllResidentsWithExistingStatement.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents/with-existing-statement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentsWithExistingStatement200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all residents that have at least one statement record associated
     */
    async findAllResidentsWithExistingStatement(requestParameters: FindAllResidentsWithExistingStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentsWithExistingStatement200Response> {
        const response = await this.findAllResidentsWithExistingStatementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all residents with no bills
     */
    async findAllResidentsWithNoBillsRaw(requestParameters: FindAllResidentsWithNoBillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentsWithNoBills200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllResidentsWithNoBills.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling findAllResidentsWithNoBills.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling findAllResidentsWithNoBills.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/residents/no-bills`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentsWithNoBills200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all residents with no bills
     */
    async findAllResidentsWithNoBills(requestParameters: FindAllResidentsWithNoBillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentsWithNoBills200Response> {
        const response = await this.findAllResidentsWithNoBillsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Move out a resident
     */
    async moveOutResidentRaw(requestParameters: MoveOutResidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling moveOutResident.');
        }

        if (requestParameters.residentMoveOut === null || requestParameters.residentMoveOut === undefined) {
            throw new runtime.RequiredError('residentMoveOut','Required parameter requestParameters.residentMoveOut was null or undefined when calling moveOutResident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/residents/{id}/move-out`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentMoveOutToJSON(requestParameters.residentMoveOut),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Move out a resident
     */
    async moveOutResident(requestParameters: MoveOutResidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveOutResidentRaw(requestParameters, initOverrides);
    }

}
