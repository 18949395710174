import React from 'react';
import { UseFormReturn } from 'react-hook-form';
React;
import { Typography } from '@mui/material';

import { TaskExtensionQuestionType } from '@/models/TaskExtensionModel';

import TaskExtensionPreview from '../TaskExtensionPreview';

import { InstanceWithTaskExtensionFormType } from '.';

interface TaskExtensionPreviewFormProps {
  formMethods: UseFormReturn<InstanceWithTaskExtensionFormType>;
  questionType: TaskExtensionQuestionType;
  disabled?: boolean;
}

export default function TaskExtensionPreviewForm(
  props: TaskExtensionPreviewFormProps
) {
  const { formMethods, questionType, disabled = false } = props;

  const taskExtension = formMethods.watch('task_extension');

  return (
    <>
      {((taskExtension.default_answer_options &&
        taskExtension.default_answer_options.length > 0) ||
        (taskExtension.default_answers &&
          taskExtension.default_answers.length > 0)) && (
        <>
          <Typography variant="inherit" fontWeight={500}>
            Preview of Extension
          </Typography>
          <TaskExtensionPreview
            disabled={disabled}
            answers={
              taskExtension.default_answers
                ? taskExtension.default_answers
                : questionType === TaskExtensionQuestionType.FreeText ||
                  questionType === TaskExtensionQuestionType.Number ||
                  questionType === TaskExtensionQuestionType.Percentage
                ? taskExtension.default_answers!
                : taskExtension.default_answer_options!
            }
            taskExtension={taskExtension}
          />
        </>
      )}
    </>
  );
}
