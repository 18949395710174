import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const CRMScreener = loadable(() => import("@/pages/CRM/Screener")); // prettier-ignore
const CRMScreenerAllLeads = loadable(() => import("@/pages/CRM/Screener/Leads/AllLeads")); // prettier-ignore
const CRMScreenerSingleLead = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead")); // prettier-ignore
const CRMScreenerAllSources = loadable(() => import("@/pages/CRM/Screener/Sources/AllSources")); // prettier-ignore
const CRMScreenerSingleSource = loadable(() => import("@/pages/CRM/Screener/Sources/SingleSource")); // prettier-ignore
const CRMScreenerSettings = loadable(() => import("@/pages/CRM/Screener/Settings")); // prettier-ignore
const CRMScreenerSettingsRules = loadable(() => import("@/pages/CRM/Screener/Settings/SettingsRules")); // prettier-ignore
const CRMScreenerDashboard = loadable(() => import("@/pages/CRM/Screener/Dashboard")); // prettier-ignore
const CRMScreenerUsageOverview = loadable(() => import("@/pages/CRM/Screener/UsageOverview")); // prettier-ignore

export enum CRMScreenerRoutes {
  CRMScreener = '/crm/screener',
  CRMScreenerAllSources = '/crm/screener/sources',
  CRMScreenerSingleSource = '/crm/screener/sources/:source_id',
  CRMScreenerAllLeads = '/crm/screener/leads',
  CRMScreenerSingleLead = '/crm/screener/leads/:lead_id',
  CRMScreenerSettings = '/crm/screener/settings',
  CRMScreenerSettingsRules = '/crm/screener/settings/rules',
  CRMScreenerSettingsDataPulls = '/crm/screener/settings/data-pulls',
  CRMScreenerDashboard = '/crm/screener/dashboard',
  CRMScreenerUsageOverview = '/crm/screener/usage-overview'
}

export const getCrmScreenerRoutes = (): RouteObject[] => {
  return FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)
    ? [
        {
          path: CRMScreenerRoutes.CRMScreener,
          element: <CRMScreener />,
          children: [
            {
              path: CRMScreenerRoutes.CRMScreenerAllLeads,
              element: <CRMScreenerAllLeads />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerAllSources,
              element: <CRMScreenerAllSources />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerSettings,
              element: <CRMScreenerSettings />,
              children: [
                {
                  path: CRMScreenerRoutes.CRMScreenerSettingsRules,
                  element: <CRMScreenerSettingsRules />
                }
              ]
            },
            {
              path: CRMScreenerRoutes.CRMScreenerDashboard,
              element: <CRMScreenerDashboard />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerUsageOverview,
              element: <CRMScreenerUsageOverview />
            }
          ]
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLead,
          element: <CRMScreenerSingleLead />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleSource,
          element: <CRMScreenerSingleSource />
        }
      ]
    : [];
};
