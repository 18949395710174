import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Alert, AlertTitle, Button, Link, Stack } from '@mui/material';

import { FrequencyTimeForm } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { medicationFrequencyOptions } from '@/components/FrequencyTimeForm/options';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { DrawerFooter, MedicationDrawerProps } from '../MedicationDrawer';

import DirectionsRecommendations from './DirectionsRecommendations';
import { MedicationRegimens } from './MedicationRegimens';

interface MedicationTimeProps {
  residentId: string;
  formMethods: UseFormReturn<ResidentMedicationForm>;
  handleBack: VoidFunction;
  handleNext: VoidFunction;
  type: MedicationDrawerProps['type'];
  isStartTimeEditable?: boolean;
  shouldShowDirectionsRecommendations?: boolean;
  responsibleParties?: ResponsibleParty[];
}

export const MedicationTime: React.FC<MedicationTimeProps> = ({
  residentId,
  formMethods,
  handleBack,
  handleNext,
  type,
  isStartTimeEditable = true,
  shouldShowDirectionsRecommendations,
  responsibleParties
}) => {
  const [responsibleParty] = formMethods.watch(['responsible_party']);
  // TODO: Make the "here" link actually open up the responsible party select.
  // This is tricky because the select is not a native select, but a custom
  // one. We need to figure out how to open it up.

  const isStaffResponsibility = useCallback(isStaffResponsibilityV2, [
    responsibleParty,
    responsibleParties
  ]);

  return (
    <>
      <Stack gap={3}>
        {shouldShowDirectionsRecommendations && (
          <DirectionsRecommendations
            directions={formMethods.getValues('directions') ?? 'N/A'}
          />
        )}
        {!isStaffResponsibility(responsibleParty, responsibleParties) && (
          <Alert severity="info" data-testid="non-staff-party-alert-time">
            <AlertTitle>Non-Staff Responsible party selected</AlertTitle>
            Medication schedule and dose selection is not applicable for
            non-staff responsible parties. Tasks are not generated and doses are
            not tracked. If you would like to generate tasks for this
            medication, please select a different responsible party{' '}
            <Link href="#" onClick={handleBack}>
              here
            </Link>
            .
          </Alert>
        )}
        {isStaffResponsibility(responsibleParty, responsibleParties) ? (
          FeatureFlagService.isEnabled(
            ExacareFeature.MEDICATION_TASK_REGIMENS
          ) ? (
            <>
              <MedicationRegimens
                residentId={residentId}
                formMethods={formMethods}
                type={type}
                isStartTimeEditable={isStartTimeEditable}
              />
            </>
          ) : (
            <FrequencyTimeForm
              residentId={residentId}
              formMethods={formMethods}
              frequencyOptions={medicationFrequencyOptions}
              isMedicationsDrawer
              type={type}
              isStartTimeEditable={isStartTimeEditable}
            />
          )
        ) : null}
      </Stack>
      {type === 'Add' && (
        <DrawerFooter>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </DrawerFooter>
      )}
    </>
  );
};
