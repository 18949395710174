import React, { useEffect } from 'react';
import {
  Clear,
  Close,
  Search,
  Search as SearchIcon
} from '@mui/icons-material';
import {
  IconButton,
  Popover,
  styled,
  TextField,
  Theme,
  useMediaQuery
} from '@mui/material';
import {
  GridInitialState,
  gridQuickFilterValuesSelector,
  GridToolbarQuickFilterProps,
  useGridApiContext
} from '@mui/x-data-grid-pro';
import debounce from 'lodash/debounce';
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';

export const gridInitialState = {} as Record<string, GridInitialState>;

/**
 * This component is used to render the quick filter input field in the DataGrid
 * toolbar. It is responsive and will render a popover on mobile devices. It
 * also uses the `useGridApiContext` hook to access the `setQuickFilterValues`,
 * so it must rendered within the `DataGridPro` context.
 */
export const GridToolbarQuickFilter: React.FC<
  GridToolbarQuickFilterProps & {
    defaultSearch?: string;
    color?: 'primary' | 'secondary';
  }
> = (props) => {
  const gridApiRef = useGridApiContext();
  const quickFilterValues =
    gridQuickFilterValuesSelector(gridApiRef)?.[0] ?? '';
  const [focused, setFocused] = React.useState(false);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'quickFilterPopover'
  });
  const inputRef = React.createRef<HTMLInputElement>();
  const debouncedSetQuickFilterValues = debounce((value: string) => {
    gridApiRef.current.setQuickFilterValues([value]);
  }, 500);

  React.useEffect(() => {
    if (popupState.isOpen) {
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  }, [popupState]);
  const handleSubmit = (e: React.MouseEvent | React.FormEvent) => {
    e.preventDefault();
    popupState.close();
  };
  const handleClose = () => {
    handleReset();
    popupState.close();
  };
  const handleReset = () => {
    gridApiRef.current.setQuickFilterValues([]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  useEffect(() => {
    if (!props.defaultSearch) return;
  }, [props.defaultSearch]);

  const bindedTextField = (
    <StyledTextField
      {...props}
      defaultValue={
        Array.isArray(quickFilterValues)
          ? quickFilterValues?.[0]
          : quickFilterValues
      }
      className={focused ? 'focused' : ''}
      data-testid="search-bar-input"
      onChange={(e) => debouncedSetQuickFilterValues(e.target.value)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      variant="standard"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <SearchIcon
            htmlColor="#4E616D"
            sx={{ marginRight: '10px', height: '0.8em', width: '0.8em' }}
          />
        ),
        endAdornment: (quickFilterValues?.length ?? 0) > 0 && (
          <Clear
            sx={{
              width: '18px',
              height: '18px',
              color: '#9AAEBB',
              cursor: 'pointer'
            }}
            onClick={() => handleReset()}
          />
        ),
        autoCapitalize: 'off',
        autoCorrect: 'off',
        autoComplete: 'off',
        disableUnderline: true,
        placeholder: 'Search'
      }}
    />
  );

  return isMediaQueryDownLg ? (
    <>
      <StyledSearchButton
        color={props.color}
        data-testid="search-bar-button"
        {...bindTrigger(popupState)}
        hasSearchQuery={(quickFilterValues?.length ?? 0) > 0 ? true : false}>
        <Search
          fontSize="small"
          htmlColor={props.color === 'secondary' ? '#667A86' : '#9AAEBB'}
        />
      </StyledSearchButton>
      <StyledPopover {...bindPopover(popupState)}>
        <form onSubmit={handleSubmit}>{bindedTextField}</form>
        <IconButton onClick={handleClose} size="small">
          <Close
            htmlColor={props.color === 'secondary' ? '#667A86' : '#9AAEBB'}
          />
        </IconButton>
      </StyledPopover>
    </>
  ) : (
    bindedTextField
  );
};

const hasSearchQueryMixin = `
  background: #ffffff;
  border: 1px solid #1db8f2;
  box-shadow: 0px 0px 5px #009bd0;
`;

export const StyledSearchButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'hasSearchQuery' && prop !== 'color'
})<{
  hasSearchQuery: boolean;
  color?: 'primary' | 'secondary';
}>`
  background: ${({ color }) =>
    color === 'secondary' ? 'transparent' : '#e7eef4'};
  width: 42px;
  height: 42px;
  ${({ hasSearchQuery }) => hasSearchQuery && hasSearchQueryMixin}
`;

export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    width: 300px;
    height: 82px;
    box-shadow: 0px 5px 20px 2px rgba(44, 58, 66, 0.15);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 8px;
  }
`;

export const StyledTextField = styled(TextField)`
  transition: all 0.15s ease-in-out;
  width: 220px;
  height: 42px;
  border-radius: 1000px;
  padding: 5px 14px 0 12px;
  color: #20333e;
  filter: drop-shadow(0px 5px 10px rgba(117, 136, 153, 0.1));
  background: #e7eef4;
  border: 1px solid #e7eef4;
  &.focused {
    border: 1px solid #1db8f2;
    background: #fcfeff;
  }
  & input {
    font-size: 14px;
    margin: 1px 0 0 0;
    ::placeholder {
      color: #364955;
      opacity: 0.75;
      font-size: 14px;
    }
  }
`;
