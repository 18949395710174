import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

const AdminHubProviders = loadable(() => import("@/pages/AdminHub/Providers")); // prettier-ignore
const OrganizationDoctorContacts = loadable(() => import("@/pages/Organization/OrganizationDoctorContacts")); // prettier-ignore
const OrganizationFuneralHomes = loadable(() => import("@/pages/Organization/OrganizationFuneralHomes")); // prettier-ignore
const OrganizationHospitals = loadable(() => import("@/pages/Organization/OrganizationHospitals")); // prettier-ignore
const OrganizationOtherProviders = loadable(() => import("@/pages/Organization/OrganizationOtherProviders")); // prettier-ignore
const OrganizationPharmacies = loadable(() => import("@/pages/Organization/OrganizationPharmacies")); // prettier-ignore

export enum AdminHubProviderRoutes {
  AdminHubProviders = '/admin-hub/providers',
  AdminHubDoctorContacts = '/admin-hub/providers/doctor-contacts',
  AdminHubPharmacies = '/admin-hub/providers/pharmacies',
  AdminHubHospitals = '/admin-hub/providers/hospitals',
  AdminHubFuneralHomes = '/admin-hub/providers/funeral-homes',
  AdminHubOtherProviders = '/admin-hub/providers/other-providers'
}

export const getEhrAdminHubProviderRoutes = (): RouteObject[] => {
  return [
    {
      path: AdminHubProviderRoutes.AdminHubProviders,
      element: <AdminHubProviders />,
      children: [
        {
          path: AdminHubProviderRoutes.AdminHubDoctorContacts,
          element: <OrganizationDoctorContacts />
        },
        {
          path: AdminHubProviderRoutes.AdminHubPharmacies,
          element: <OrganizationPharmacies />
        },
        {
          path: AdminHubProviderRoutes.AdminHubHospitals,
          element: <OrganizationHospitals />
        },
        {
          path: AdminHubProviderRoutes.AdminHubFuneralHomes,
          element: <OrganizationFuneralHomes />
        },
        {
          path: AdminHubProviderRoutes.AdminHubOtherProviders,
          element: <OrganizationOtherProviders />
        }
      ]
    }
  ];
};
