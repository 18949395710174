import * as React from 'react';
import {
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  SvgIconTypeMap
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

export type DialogStep = {
  key: number;
  Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  label: string;
};

interface DialogStepperProps {
  steps: DialogStep[];
  step: number;
  handleStepClick?: (step: number) => void;
}

export const DialogStepper: React.FC<DialogStepperProps> = ({
  step,
  steps,
  handleStepClick
}) => {
  return (
    <Stack direction="row" justifyContent="center" width="100%">
      <StyledStepper
        alternativeLabel
        activeStep={step}
        connector={<ColorlibConnector />}
        handleStepClick={handleStepClick}>
        {steps.map(({ key, label, Icon }) => (
          <Step
            key={key}
            onClick={() => handleStepClick?.(key)}
            disabled={typeof handleStepClick !== 'function'}>
            <StepLabel icon={<Icon />} StepIconComponent={ColorlibStepIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </Stack>
  );
};

const StyledStepper = styled(Stepper, {
  shouldForwardProp: (prop) => prop !== 'handleStepClick'
})<Pick<DialogStepperProps, 'handleStepClick'>>(({ handleStepClick }) => ({
  borderRadius: '16px',
  px: '0',
  width: 560,
  '& .MuiStepLabel-label.Mui-completed': {
    fontWeight: 400
  },
  '& .MuiStep-root': {
    cursor: handleStepClick ? 'pointer' : 'default'
  }
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  backgroundColor: '#9AAEBB',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#1DB8F2',
    color: '#FFFFFF',
    border: '1px solid #1DB8F2'
  }),
  ...(ownerState.completed && {
    backgroundColor: '#BCE8C6'
  }),
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem'
  }
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}>
      {icon}
    </ColorlibStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)({
  top: 17,
  left: 'calc(-50% + 40px)',
  right: 'calc(50% + 40px)',
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    background: '#9AAEBB',
    borderRadius: 1
  }
});
