import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ProtectedRoute } from '../ProtectedRoute';

const AddStaffFacesheet = loadable(() => import("@/pages/AddStaffPage/AddStaffFacesheet")); // prettier-ignore
const AddStaffPage = loadable(() => import("@/pages/AddStaffPage")); // prettier-ignore
const AddStaffRecords = loadable(() => import("@/pages/AddStaffPage/AddStaffRecords")); // prettier-ignore
const AddStaffRegistrationLink = loadable(() => import("@/pages/AddStaffPage/AddStaffRegistrationLink")); // prettier-ignore

export enum AddStaffRoutes {
  AddStaff = '/add-staff',
  AddStaffFacesheet = '/add-staff/facesheet',
  AddStaffRecords = '/add-staff/records',
  AddStaffRegistrationLink = '/add-staff/registration-link'
}

export const getEhrAddStaffRoutes = (): RouteObject[] => {
  return [
    {
      path: AddStaffRoutes.AddStaff,
      element: <ProtectedRoute component={AddStaffPage} role="L1" />,
      children: [
        {
          index: true,
          path: AddStaffRoutes.AddStaffFacesheet,
          element: <AddStaffFacesheet />
        },
        {
          path: AddStaffRoutes.AddStaffRecords,
          element: <AddStaffRecords />
        },
        {
          path: AddStaffRoutes.AddStaffRegistrationLink,
          element: <AddStaffRegistrationLink />
        }
      ]
    }
  ];
};
