import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { HTTPError } from 'ky';

import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import ErrorPage from '@/pages/ErrorPage';

export const ResidentLoader: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const residentId = useParams().resident_id;
  const { error, isFetching } = useFindOneResidentQuery(
    { residentId },
    {
      retry: false
    }
  );

  if (isFetching) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (error instanceof HTTPError && error.response.status === 403) {
    return (
      <ErrorPage
        title="Access Forbidden"
        message="You are unable to view this resident because you do not have sufficient permissions. Please speak with your administrator in order to gain access."
      />
    );
  }

  if (error instanceof HTTPError && error.response.status === 404) {
    return (
      <ErrorPage
        title="Requested Resident Not Found"
        message="This resident does not exist. Please check the URL and try again."
      />
    );
  }

  return <>{children}</>;
};
