import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ProtectedRoute } from '../ProtectedRoute';
import { RoutePath } from '../RoutePath';

const AdminHub = loadable(() => import("@/pages/AdminHub")); // prettier-ignore

const AdminHubIncidents = loadable(() => import("@/pages/AdminHub/IncidentsV2")); // prettier-ignore
const AdminHubIncidentsProtocol = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsProtocol")); // prettier-ignore
const AdminHubIncidentsProtocolCreate = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsProtocol/CreateIncidentProtocolPage")); // prettier-ignore
const AdminHubIncidentsReview = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsReview")); // prettier-ignore
const AdminHubIncidentsSettings = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsSettings")); // prettier-ignore
const AdminHubManageCustomMedList = loadable(() => import("@/pages/AdminHub/ManageCustomMedList")); // prettier-ignore
const AdminHubNotificationPreferences = loadable(() => import("@/pages/AdminHub/Notifications")); // prettier-ignore
const AdminHubFacilities = loadable(() => import("@/pages/Organization/OrganizationFacilities")); // prettier-ignore
const CommunitySettings = loadable(() => import("@/components/CommunitySettings")); // prettier-ignore
const AssessmentBuilder = loadable(() => import("@/pages/AdminHub/AssessmentBuilder")); // prettier-ignore

export enum AdminHubGeneralRoutes {
  AdminHub = '/admin-hub',
  AdminHubNotificationPreferences = '/admin-hub/notification-preferences',
  AdminHubCommunities = '/admin-hub/communities',
  AdminHubCommunity = '/admin-hub/community/:facility_id',
  AdminHubManageCustomMedList = '/admin-hub/custom-med-list/:facility_id',
  AdminHubIncidents = '/admin-hub/incidents',
  AdminHubIncidentsReview = '/admin-hub/incidents/review',
  AdminHubIncidentsProtocol = '/admin-hub/incidents/protocols',
  AdminHubIncidentsSettings = '/admin-hub/incidents/settings',
  AdminHubIncidentsProtocolCreate = '/admin-hub/incidents/create-protocol/:protocol'
}

export const getEhrAdminHubGeneralRoutes = (): RouteObject[] => {
  return [
    {
      path: AdminHubGeneralRoutes.AdminHub,
      element: <ProtectedRoute component={AdminHub} role="L1" />,
      children: [
        {
          path: RoutePath.AdminHubAssessment,
          element: <AssessmentBuilder />
        },
        {
          path: AdminHubGeneralRoutes.AdminHubIncidents,
          element: <AdminHubIncidents />,
          children: [
            {
              path: AdminHubGeneralRoutes.AdminHubIncidentsReview,
              element: <AdminHubIncidentsReview />
            },
            {
              path: AdminHubGeneralRoutes.AdminHubIncidentsProtocol,
              element: <AdminHubIncidentsProtocol />
            },
            {
              path: AdminHubGeneralRoutes.AdminHubIncidentsSettings,
              element: <AdminHubIncidentsSettings />
            }
          ]
        },
        {
          path: AdminHubGeneralRoutes.AdminHubIncidentsProtocolCreate,
          element: <AdminHubIncidentsProtocolCreate />
        },

        {
          path: AdminHubGeneralRoutes.AdminHubCommunities,
          element: (
            <AdminHubFacilities
              navigateToAdminHubCommunities
              includeBackButton
            />
          )
        },
        {
          path: AdminHubGeneralRoutes.AdminHubCommunity,
          element: <CommunitySettings includeBackButton />
        },
        ...(FeatureFlagService.isEnabled(
          ExacareFeature.VITE_FEATURE_FLAG_NOTIFICATIONS
        )
          ? [
              {
                path: RoutePath.AdminHubNotificationPreferences,
                element: (
                  <ProtectedRoute
                    role="L1"
                    component={AdminHubNotificationPreferences}
                  />
                )
              }
            ]
          : []),
        {
          path: RoutePath.AdminHubManageCustomMedList,
          element: <AdminHubManageCustomMedList />
        }
      ]
    }
  ];
};
