import React, { useContext, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Check, Delete, FileCopy, InsertDriveFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import prettyBytes from 'pretty-bytes';

import postAccessUrl from '@/api/postAccessUrl';
import { postS3Files, UploadProgressState } from '@/api/postS3Files';
import { UploadCircularProgress } from '@/components/CircularProgress';
import { ControlledSelect } from '@/components/ControlledSelect';
import { ControlledTextField } from '@/components/ControlledTextField';
import { Dropzone } from '@/components/Dropzone';
import { FormHeader } from '@/components/FormHeader';
import { useResidentDoctorsQuery } from '@/hooks/useDoctorsQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

import { DrawerFooter, MedicationDrawerProps } from '../MedicationDrawer';

const prescriptionTypeOptions = [
  'Fax',
  'Email',
  'Handwritten',
  'Called-in',
  'Scanned'
];

// TODO:
// Refactor these to come from API
const sigCodeDescriptions = [
  'TAKE ONCE PER DAY',
  'TAKE 1 CAPSULE BY MOUTH DAILY',
  'TAKE 1 TABLET BY MOUTH DAILY',
  'TAKE TWICE PER DAY',
  'TAKE 1 CAPSULE BY MOUTH TWICE DAILY',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY',
  'TAKE THREE TIMES PER DAY',
  'TAKE FOUR TIMES PER DAY',
  'TAKE FIVE TIMES PER DAY',
  'TAKE 1 CAPSULE BY MOUTH THREE TIMES DAILY',
  'TAKE 1 TABLET BY MOUTH THREE TIMES DAILY',
  'TAKE MEDICATION AS NEEDED',
  'TAKE MEDICATION ON AN EMPTY STOMACH',
  'TAKE 1 TABLET BY MOUTH ONCE PER WEEK'
];

interface MedicationPrescriptionProps {
  formMethods: UseFormReturn<ResidentMedicationForm>;
  handleBack: VoidFunction;
  handleNext: VoidFunction;
  isSaving?: boolean;
  type: MedicationDrawerProps['type'];
  residentId: string;
}

export const MedicationPrescription: React.FC<MedicationPrescriptionProps> = ({
  formMethods,
  handleBack,
  handleNext,
  isSaving,
  residentId,
  type
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const [progress, setProgress] = React.useState({} as UploadProgressState);
  const { showSnackbar } = useSnackbar();
  const [doctorId, s3Prescription] = formMethods.watch([
    'doctor_id',
    's3_prescription'
  ]);

  const { data: doctors = [], isLoading } = useResidentDoctorsQuery(residentId);

  useEffect(() => {
    if (!doctors || isLoading) return;
    const doctor = doctors.find((v) => v.id == doctorId);
    formMethods.setValue('doctor_id', doctor?.id as string, {
      shouldDirty: true
    });
  }, [doctors]);

  const handleOnFileUpload = async (acceptedFiles: File[]) => {
    try {
      const files = await postS3Files(acceptedFiles, setProgress);
      formMethods.setValue('s3_prescription', files[0]);
      showSnackbar({
        message: `Successfully uploaded "${files[0].name}" prescription image`,
        severity: 'success'
      });
    } catch (e) {
      showSnackbar({
        message: `Failed to upload "${acceptedFiles[0].name}" prescription image`,
        severity: 'error'
      });
    }
  };

  const handleOnFileRemove: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    formMethods.setValue('s3_prescription', null);
  };

  const handleDownloadPrescription = async () => {
    if (s3Prescription) {
      try {
        await postAccessUrl([s3Prescription.key]).then(([{ url }]) => {
          window.open(url, '_blank');
        });
      } catch (e) {
        showSnackbar({
          message: 'Failed to download prescription. Please try again',
          severity: 'error'
        });
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FormHeader Icon={FileCopy} text="Prescription Review" mb={1} />
        </Grid>
        <Grid xs={12} md={6}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              disabled={isResidentArchived}
              autoHighlight
              value={doctors.find((v) => v.id == doctorId)}
              options={doctors}
              loading={isLoading}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                formMethods.setValue('doctor_id', value?.id as string);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Prescriber" />
              )}
            />
          )}
        </Grid>
        <Grid xs={12} md={6}>
          <ControlledSelect
            disabled={isResidentArchived}
            control={formMethods.control}
            name="prescription_type"
            label="Prescription Type"
            options={prescriptionTypeOptions}
          />
        </Grid>
        {!isResidentArchived && (
          <Grid xs={12}>
            <Dropzone
              onDrop={handleOnFileUpload}
              maxFiles={1}
              accept={{ 'image/*': [], 'application/pdf': [] }}
              inputProps={{ capture: 'environment' }}
            />
          </Grid>
        )}
        {progress.file && (
          <ListItem>
            <ListItemIcon>
              <UploadCircularProgress value={progress.loaded} />
            </ListItemIcon>
            <ListItemText
              primary={progress.file.name}
              secondary={progress.file.type}
            />
          </ListItem>
        )}
        {s3Prescription && !isResidentArchived && (
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={handleOnFileRemove}>
                <Delete />
              </IconButton>
            }>
            <ListItemAvatar>
              <Avatar>
                <InsertDriveFile />
              </Avatar>
            </ListItemAvatar>
            <Link
              href="#"
              target="_blank"
              rel="noreferrer"
              underline="hover"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadPrescription();
              }}>
              <ListItemText
                primary={s3Prescription?.name}
                secondary={
                  s3Prescription.size > 0 && prettyBytes(s3Prescription.size)
                }
              />
            </Link>
          </ListItem>
        )}
        <Grid xs={12}>
          <FormHeader Icon={Check} text="Instruction" m="16px 0 8px" />
        </Grid>
        <Grid xs={12}>
          <ControlledTextField
            disabled={isResidentArchived}
            control={formMethods.control}
            name="diagnosis"
            label="Diagnosis"
            placeholder="Emphysema"
          />
        </Grid>
        <Grid xs={12}>
          <Autocomplete
            disablePortal
            id="instructions-autocomplete"
            options={sigCodeDescriptions}
            disabled={isResidentArchived}
            freeSolo={true}
            value={formMethods.getValues('directions')}
            onInputChange={(event, value) => {
              formMethods.setValue('directions', value, { shouldDirty: true });
            }}
            renderInput={(params) => (
              <TextField
                multiline
                minRows={3}
                {...params}
                label="Directions for use"
                placeholder="Select or type directions for use"
              />
            )}
            sx={{
              '& .MuiAutocomplete-endAdornment': {
                top: '8px'
              }
            }}
          />
        </Grid>
      </Grid>
      {type === 'Add' && (
        <DrawerFooter>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleNext}
            loading={isSaving}>
            Next
          </LoadingButton>
        </DrawerFooter>
      )}
    </>
  );
};
