import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { CrmReferralSourceLoader } from '../middleware/CrmReferralSourceLoader';
import { ProtectedRoute } from '../ProtectedRoute';

const CRMReferralSourcesPage = loadable(() => import("@/pages/CRM/ReferralSourcesPage")); // prettier-ignore
const CRMReferralSources = loadable(() => import("@/pages/CRM/ReferralSourcesPage/ReferralSources")); // prettier-ignore
const CRMReferralSourcePage = loadable(() => import("@/pages/CRM/ReferralSourcePage")); // prettier-ignore
const CRMReferralSourceFacesheetPage = loadable(() => import("@/pages/CRM/ReferralSourcePage/ReferralSourceFacesheet")); // prettier-ignore
const CRMReferralSourceNotesPage = loadable(() => import("@/pages/CRM/ReferralSourcePage/ReferralSourceNotes")); // prettier-ignore
const CRMReferralSourceTasksPage = loadable(() => import("@/pages/CRM/ReferralSourcePage/ReferralSourceTasks")); // prettier-ignore
const CRMReferralSourceHistoryPage = loadable(() => import("@/pages/CRM/ReferralSourcePage/ReferralSourceHistory")); // prettier-ignore
const CRMReferralSourceCompaniesPage = loadable(() => import("@/pages/CRM/ReferralSourcesPage/Companies")); // prettier-ignore
const CRMReferralSourceCompany = loadable(() => import("@/pages/CRM/ReferralSourceCompanyPage")); // prettier-ignore
const CRMReferralSourceCompanyReferralSources = loadable(() => import("@/pages/CRM/ReferralSourceCompanyPage/ReferralSources")); // prettier-ignore

export enum CRMReferralSourceRoutes {
  CRMReferralSources = '/crm/referral-sources',
  CRMReferralSourceCompanies = '/crm/referral-sources/companies',
  CRMReferralSource = '/crm/referral-source/:referral_source_id',
  CRMReferralSourceFacesheet = '/crm/referral-source/:referral_source_id/facesheet',
  CRMReferralSourceNotes = '/crm/referral-source/:referral_source_id/notes',
  CRMReferralSourceTasks = '/crm/referral-source/:referral_source_id/tasks',
  CRMReferralSourceHistory = '/crm/referral-source/:referral_source_id/history',
  CRMReferralSourceCompany = '/crm/referral-source-company/:referral_source_company_id',
  CRMReferralSourceCompanyReferralSources = '/crm/referral-source-company/:referral_source_company_id/referral-sources'
}

export const getCrmReferralSourcesRoutes = (): RouteObject[] => {
  return [
    {
      path: CRMReferralSourceRoutes.CRMReferralSources,
      element: <CRMReferralSourcesPage />,
      children: [
        {
          path: CRMReferralSourceRoutes.CRMReferralSources,
          element: <CRMReferralSources />
        },
        {
          path: CRMReferralSourceRoutes.CRMReferralSourceCompanies,
          element: <CRMReferralSourceCompaniesPage />
        }
      ]
    },
    {
      path: CRMReferralSourceRoutes.CRMReferralSourceCompany,
      element: <CRMReferralSourceCompany />,
      children: [
        {
          path: CRMReferralSourceRoutes.CRMReferralSourceCompanyReferralSources,
          element: <CRMReferralSourceCompanyReferralSources />
        }
      ]
    },
    {
      path: CRMReferralSourceRoutes.CRMReferralSource,
      element: (
        <CrmReferralSourceLoader>
          <CRMReferralSourcePage />
        </CrmReferralSourceLoader>
      ),
      children: [
        {
          path: CRMReferralSourceRoutes.CRMReferralSourceFacesheet,
          element: <CRMReferralSourceFacesheetPage />
        },
        {
          path: CRMReferralSourceRoutes.CRMReferralSourceNotes,
          element: <CRMReferralSourceNotesPage />
        },
        {
          path: CRMReferralSourceRoutes.CRMReferralSourceTasks,
          element: <CRMReferralSourceTasksPage />
        },
        ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_ACTIVITY_LOG)
          ? [
              {
                path: CRMReferralSourceRoutes.CRMReferralSourceHistory,
                element: <CRMReferralSourceHistoryPage />
              }
            ]
          : [])
      ]
    }
  ];
};
