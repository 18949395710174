import React from 'react';
import { UseFormReturn } from 'react-hook-form';
React;

import { ControlledSelectPrimitive } from '@/components/ControlledSelectPrimitive';
import { ControlledTextField } from '@/components/ControlledTextField';
import {
  TaskExtensionModel,
  TaskExtensionQuestionType,
  taskExtensionQuestionTypeOptions,
  TaskExtensionType
} from '@/models/TaskExtensionModel';

import TaskExtensionTemplateForm from './TaskExtensionTemplateForm';
import { InstanceWithTaskExtensionFormType } from '.';

interface TaskExtensionCommonFieldsFormProps {
  formMethods: UseFormReturn<InstanceWithTaskExtensionFormType>;
  taskExtensionType: TaskExtensionType;
  cleanTemplateSelect: VoidFunction;
  resetFirstDefaultAnswer: VoidFunction;
  selectedTemplateId: string;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  isOrgWide?: boolean;
}

export default function TaskExtensionCommonFieldsForm(
  props: TaskExtensionCommonFieldsFormProps
) {
  const {
    formMethods,
    taskExtensionType,
    cleanTemplateSelect,
    resetFirstDefaultAnswer,
    selectedTemplateId,
    setSelectedTemplateId,
    disabled = false,
    isOrgWide = false
  } = props;

  return (
    <>
      <TaskExtensionTemplateForm
        disabled={disabled}
        cleanTemplateSelect={cleanTemplateSelect}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        formMethods={formMethods}
        taskExtensionType={taskExtensionType}
        isOrgWide={isOrgWide}
      />
      <ControlledTextField
        disabled={disabled}
        control={formMethods.control}
        name="task_extension.title"
        label="Title"
        rules={{ required: true }}
        onChange={(e) => {
          cleanTemplateSelect();
          formMethods.setValue(
            'task_extension.title',
            e.target.value.substring(0, 15)
          );
        }}
      />
      <ControlledTextField
        disabled={disabled}
        control={formMethods.control}
        name="task_extension.description"
        label="Description"
        onChange={() => cleanTemplateSelect()}
        multiline
        rows={4}
      />
      <ControlledSelectPrimitive
        disabled={disabled}
        control={formMethods.control}
        name="task_extension.question_type"
        label="Question Type"
        options={taskExtensionQuestionTypeOptions}
        optionIdKey="value"
        optionLabelKey="label"
        rules={{ required: true }}
        onChange={(value) => {
          cleanTemplateSelect();
          if (
            value === TaskExtensionQuestionType.FreeText ||
            value === TaskExtensionQuestionType.Number ||
            value === TaskExtensionQuestionType.Percentage
          ) {
            resetFirstDefaultAnswer();
          } else {
            const defaultAnswerOptions = formMethods.getValues(
              'task_extension.default_answer_options'
            );
            formMethods.setValue(
              'task_extension.default_answers',
              TaskExtensionModel.toTaskExtensionDefaultAnswersForm(
                defaultAnswerOptions,
                null,
                value as TaskExtensionQuestionType
              )
            );
          }
        }}
      />
    </>
  );
}
