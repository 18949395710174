import React from 'react';
import { useRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import SecretFeatureFlagPage from '@/pages/SecretFeatureFlagPage';

import { getEhrRoutes } from './getEhrRoutes/getEhrRoutes';
import { getCrmRoutes } from './getCrmRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { RoutePath } from './RoutePath';

const RouteConfig = () => {
  const currentUser = useCurrentUser().data!;

  return useRoutes([
    ...getCrmRoutes(),
    ...getEhrRoutes(),
    ...(currentUser.email.endsWith('@exacare.com')
      ? [
          {
            path: RoutePath.SecretFeatureFlagService,
            element: (
              <ProtectedRoute role="L0" component={SecretFeatureFlagPage} />
            )
          }
        ]
      : [])
  ]);
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(RouteConfig);
export { SentryRoutes as RouteConfig };
