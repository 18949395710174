import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ProtectedRoute } from '../ProtectedRoute';

const ResidentOverviewAttendancePage = loadable(() => import("@/pages/ResidentOverviewPage/ResidentOverviewAttendance")); // prettier-ignore
const ResidentOverviewGeneralInformationPage = loadable(() => import("@/pages/ResidentOverviewPage/ResidentOverviewGeneralInformation")); // prettier-ignore
const ResidentOverviewPage = loadable(() => import("@/pages/ResidentOverviewPage")); // prettier-ignore
const ResidentOverviewPreArrivalPage = loadable(() => import("@/pages/ResidentOverviewPage/ResidentOverviewPreArrival")); // prettier-ignore
const ArchivedResidentsPage = loadable(() => import("@/pages/ArchivedResidents")); // prettier-ignore
const ResidentOverviewMARsPage = loadable(() => import("@/pages/ResidentOverviewPage/components/ResidentOverviewMARs/ResidentOverviewMARs")); // prettier-ignore

export enum ResidentOverviewRoutes {
  ResidentOverview = '/resident-overview',
  ResidentOverviewAttendance = '/resident-overview/attendance',
  ResidentOverviewPreArrival = '/resident-overview/pre-arrival',
  ResidentOverviewMARs = '/resident-overview/MARs',
  ArchivedResidents = '/resident-overview/archived-residents'
}

export const getEhrResidentOverviewRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return [
    {
      path: ResidentOverviewRoutes.ResidentOverview,
      element: <ResidentOverviewPage />,
      children: [
        {
          index: true,
          path: ResidentOverviewRoutes.ResidentOverview,
          element: <ResidentOverviewGeneralInformationPage />
        },
        {
          path: ResidentOverviewRoutes.ResidentOverviewAttendance,
          element: <ResidentOverviewAttendancePage />
        },
        ...(currentUser.hasMinimumUserRole('L1')
          ? [
              {
                path: ResidentOverviewRoutes.ResidentOverviewPreArrival,
                element: <ResidentOverviewPreArrivalPage />
              }
            ]
          : []),
        ...(FeatureFlagService.isEnabled(ExacareFeature.ARCHIVED_RESIDENTS)
          ? [
              {
                path: ResidentOverviewRoutes.ArchivedResidents,
                element: <ArchivedResidentsPage />
              }
            ]
          : [])
      ]
    },
    {
      path: ResidentOverviewRoutes.ResidentOverviewMARs,
      element: <ResidentOverviewMARsPage />
    }
  ];
};
