import isPlainObject from 'lodash/isPlainObject';

/**
 * Sanitizes strings in an object or array.

 * - Trims all string values, removing leading and trailing whitespace
 * - Converts empty strings to null
 * - Removes empty strings from arrays
 * - Recursively processes nested objects and arrays
 * - Preserves non-string values (numbers, booleans, etc.)
 * - Handles both regular object keys and symbol keys
 * 
 * @param obj - The object or array to sanitize. Can be of any type.
 * @returns The sanitized object or array with processed string values.
 *          If the input is not an object or array, it is returned as-is.
 */

export function sanitizeStrings(obj: any): any {
  if (!isPlainObject(obj) && !Array.isArray(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    // Remove empty strings and sanitize non-empty items in the array
    return obj.reduce((acc, item) => {
      if (typeof item === 'string') {
        const trimmedValue = item.trim();
        if (trimmedValue !== '') {
          acc.push(trimmedValue);
        }
      } else {
        acc.push(sanitizeStrings(item));
      }
      return acc;
    }, []);
  }

  const sanitizedObj: Record<string | symbol, any> = {};

  // Combine regular keys and symbol keys
  const allKeys = [...Object.keys(obj), ...Object.getOwnPropertySymbols(obj)];

  for (const key of allKeys) {
    const value = obj[key];
    if (typeof value === 'string') {
      const trimmedValue = value.trim();
      sanitizedObj[key] = trimmedValue === '' ? null : trimmedValue;
    } else if (typeof value === 'object') {
      sanitizedObj[key] = sanitizeStrings(value);
    } else {
      sanitizedObj[key] = value;
    }
  }

  return sanitizedObj;
}
