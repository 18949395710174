import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

const CommunitySettings = loadable(
  () => import('@/components/CommunitySettings')
); // prettier-ignore
const CRMNovaContentHubPage = loadable(() => import("@/pages/CRM/NovaContentHubPage")); // prettier-ignore
const CRMNovaContentHubActivitiesUpdatesPage = loadable(() => import("@/pages/CRM/NovaContentHubPage/ActivitiesUpdates")); // prettier-ignore

export enum CRMNovaContentHubRoutes {
  CRMNovaContentHub = '/crm/nova-content-hub',
  CRMNovaContentHubActivitiesUpdates = '/crm/nova-content-hub/activities-updates',
  CRMNovaContentHubCommunitySettings = '/crm/nova-content-hub/community-settings'
}

export const getCrmNovaContentHubRoutes = (): RouteObject[] => {
  return [
    {
      path: CRMNovaContentHubRoutes.CRMNovaContentHub,
      element: <CRMNovaContentHubPage />,
      children: [
        {
          path: CRMNovaContentHubRoutes.CRMNovaContentHubActivitiesUpdates,
          element: <CRMNovaContentHubActivitiesUpdatesPage />
        },
        {
          path: CRMNovaContentHubRoutes.CRMNovaContentHubCommunitySettings,
          element: <CommunitySettings isCRM />
        }
      ]
    }
  ];
};
