import React from 'react';
import { isMobile } from 'react-device-detect';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Intercom from '@intercom/messenger-js-sdk';
import { Box, Stack } from '@mui/material';
import * as Sentry from '@sentry/react';

import { ErrorBoundaryFallback } from '@/components/ErrorBoundaryFallback';
import LoadingScreen from '@/components/LoadingScreen';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useFacilitiesQuery } from '@/hooks/useFacilitiesQuery';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { CrmRequireSelectedFacility } from './CrmRequireSelectedFacility';
import Sidebar from './Sidebar';
import { useMobileResizeHeight } from './useMobileResizeHeight';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  window.getAccessTokenSilently = getAccessTokenSilently;
  const { isError, isLoading, error, data: user } = useCurrentUser();
  const { data: facility } = useFacilitiesQuery().findOne(user?.facility_id, {
    enabled: !!user
  });
  const containerRef = React.useRef<HTMLDivElement>(null);
  useMobileResizeHeight(containerRef);
  const isMobileViewport = useIsMobileViewport();

  if (isLoading) {
    return <LoadingScreen animate />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <Sentry.ErrorBoundary
        showDialog
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      />
    );
  }

  if (
    FeatureFlagService.isEnabled(ExacareFeature.INTERCOM) &&
    !isMobileViewport
  ) {
    Intercom({
      app_id: 'w5jnvoxy',
      user_id: user.id,
      name: user.fullName,
      email: user.email,
      facility_id: user.facility_id,
      facility_name: facility?.name,
      alignment: 'left'
    });
  }

  return isMobileViewport ? (
    <>{children}</>
  ) : (
    <Stack
      ref={containerRef}
      sx={{
        height: isMobile ? window.innerHeight : '100vh'
      }}
      direction="row">
      <Sidebar />
      <Stack
        component="main"
        className="main-content"
        direction="column"
        sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        <Box
          id="scrollable-content"
          sx={{
            margin: 2,
            marginLeft: 0,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}>
          <CrmRequireSelectedFacility>{children}</CrmRequireSelectedFacility>
        </Box>
      </Stack>
    </Stack>
  );
};

const LayoutWithAuthenticationRequired = (props: React.PropsWithChildren) => {
  // Set in CurrentUserPopover component before logging out
  const login_hint = localStorage.getItem('login_hint') ?? undefined;

  React.useEffect(() => {
    return () => {
      localStorage.removeItem('login_hint');
    };
  }, [login_hint]);

  const Rendered = withAuthenticationRequired(Layout, {
    onRedirecting: () => <LoadingScreen />,
    loginOptions: {
      login_hint
    }
  });
  return <Rendered {...props} />;
};

export default LayoutWithAuthenticationRequired;
