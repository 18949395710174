import React, { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AvTimer } from '@mui/icons-material';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import dayjs from 'dayjs';
import { RRule } from 'rrule';

import { ControlledDatePicker } from '@/components/ControlledDatePicker';
import { ControlledSelectPrimitive } from '@/components/ControlledSelectPrimitive';
import { FormHeader } from '@/components/FormHeader';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';

import { changeFrequencyHandler } from './changeFrequencyHandler';
import { CustomFrequency } from './CustomFrequency';
import { MultipleTimesADay } from './MultipleTimesADay';
import {
  dailyIntervalOptions,
  everyHourlyOrMinutelyOptions,
  ExactTimeOption,
  FrequencyOption,
  IntervalOption,
  monthlyIntervalOptions,
  PartOfDayOption,
  TypeOption,
  WeeklyDayOption,
  weeklyDayOptions,
  weeklyIntervalOptions
} from './options';
import PeriodTimeInput from './PeriodTimeInput';
import { TypeAndTimeInput } from './TypeAndTimeInput';

export type ScheduleFormData = Record<
  WeeklyDayOption['label'],
  FrequencyTimeFormData[]
>;

export enum BloodPressureType {
  Systolic = 'Systolic',
  Diastolic = 'Diastolic'
}

export interface SlidingScaleDoseRange {
  id: string;
  vital_type_id: string;
  blood_pressure_type?: BloodPressureType | null;
  from: string;
  to: string;
  number_of_dose_units: number;
  comment: string;
}

export interface FrequencyTimeFormData {
  id?: string;
  freq: FrequencyOption['value'];
  type: TypeOption['value'];
  interval: IntervalOption['value'];
  execution_window_end_time: ExactTimeOption['value'];
  execution_window_start_time: ExactTimeOption['value'];
  partOfDay: PartOfDayOption['value'];
  byweekday: WeeklyDayOption['value'];
  bymonthday: IntervalOption['value'];
  dtstart: Date;
  until: Date | null;
  number_of_dose_units: number | null;
  dose_ranges?: SlidingScaleDoseRange[] | null;
}

export function generateScheduleFormDataDefaultValues(): ScheduleFormData {
  return {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
    Everyday: []
  };
}

export interface FrequencyTimeFormProps {
  residentId: string;
  formMethods: UseFormReturn<any>;
  frequencyOptions: FrequencyOption[];
  isStartTimeEditable?: boolean;
  type?: 'Edit' | 'Add';
  isMedicationsDrawer?: boolean;
  durationInput?: React.ReactNode;
}

export const FrequencyTimeForm: React.FC<FrequencyTimeFormProps> = ({
  residentId,
  formMethods,
  isMedicationsDrawer = false,
  frequencyOptions,
  type,
  isStartTimeEditable,
  durationInput
}) => {
  const { data: resident } = useFindOneResidentQuery({ residentId });
  const [frequency] = formMethods.watch(['frequency.freq']);
  const { isResidentArchived } = useContext(ResidentStatusContext);

  React.useEffect(() => {
    // Default rrule dtstart to the resident's physical move in date or today's
    // date if null
    if (resident && !formMethods.getValues('frequency.dtstart')) {
      formMethods.setValue(
        'frequency.dtstart',
        resident.service_start_date
          ? new Date(resident.service_start_date)
          : new Date()
      );
    }
  }, [formMethods, resident]);

  const handleChangeFreq = changeFrequencyHandler({
    formMethods,
    frequencyFieldPath: 'frequency',
    scheduleFieldPath: 'schedule'
  });

  const startAndEndDateFields = (
    <>
      <Grid xs={6}>
        <ControlledDatePicker
          control={formMethods.control}
          label="Start date"
          minDate={dayjs().subtract(1, 'years').toDate()}
          name="frequency.dtstart"
          disabled={
            (type === 'Edit' && isMedicationsDrawer && !isStartTimeEditable) ||
            isResidentArchived
          }
          rules={{
            required: true,
            validate: {
              minDate: (date: any) =>
                !date ||
                (date instanceof Date &&
                  dayjs(date).isAfter(dayjs().subtract(1, 'years').toDate())) ||
                'Must not be before 1 year ago'
            }
          }}
        />
      </Grid>
      <Grid xs={6}>
        <ControlledDatePicker
          control={formMethods.control}
          label="End date (optional)"
          name="frequency.until"
          tooltip="The schedule runs indefinitely if left empty"
          disabled={isResidentArchived}
          rules={{
            validate: {
              pastDate: (date) =>
                !date ||
                (date instanceof Date &&
                  differenceInCalendarDays(date, new Date()) >= 0) ||
                'Must not be a past date',
              beforeStartDate: (date) =>
                !date ||
                (date instanceof Date &&
                  differenceInCalendarDays(
                    formMethods.getValues('frequency.dtstart'),
                    date
                  ) <= 0) ||
                'Must not be before start date'
            }
          }}
          disablePast
        />
      </Grid>
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <FormHeader Icon={AvTimer} text="Time" />
      </Grid>
      <Grid xs={12}>
        <ControlledSelectPrimitive
          control={formMethods.control}
          disabled={isResidentArchived}
          name="frequency.freq"
          label="Frequency"
          options={frequencyOptions}
          optionIdKey="value"
          optionLabelKey="label"
          rules={{ required: true }}
          onChange={(freq) => {
            handleChangeFreq(freq);
          }}
        />
      </Grid>
      {frequency === 'custom' ? (
        <>
          <Grid xs={12}>{durationInput}</Grid>
          {startAndEndDateFields}
          <CustomFrequency
            formMethods={formMethods}
            isMedicationsDrawer={isMedicationsDrawer}
            schedulePath="schedule"
            frequencyPath="frequency"
          />
        </>
      ) : (
        <>
          {frequency === RRule.DAILY && (
            <Grid xs={12}>
              <ControlledSelectPrimitive
                control={formMethods.control}
                name="frequency.interval"
                label="Pattern"
                options={dailyIntervalOptions}
                optionIdKey="value"
                optionLabelKey="label"
                rules={{ required: true }}
              />
            </Grid>
          )}
          {frequency === RRule.WEEKLY && (
            <>
              <Grid xs={6}>
                <ControlledSelectPrimitive
                  control={formMethods.control}
                  name="frequency.interval"
                  label="Recur every"
                  options={weeklyIntervalOptions}
                  optionIdKey="value"
                  optionLabelKey="label"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid xs={6}>
                <ControlledSelectPrimitive
                  control={formMethods.control}
                  name="frequency.byweekday"
                  label="Day"
                  options={weeklyDayOptions}
                  optionIdKey="value"
                  optionLabelKey="label"
                  rules={{ required: true }}
                />
              </Grid>
            </>
          )}
          {frequency === RRule.MONTHLY && (
            <Grid xs={12}>
              <ControlledSelectPrimitive
                control={formMethods.control}
                name="frequency.bymonthday"
                label="Day of the month"
                options={monthlyIntervalOptions}
                optionIdKey="value"
                optionLabelKey="label"
                rules={{ required: true }}
              />
            </Grid>
          )}
          {frequency === 'hourly-or-minutely' && (
            <>
              <Grid xs={12} md={4}>
                <ControlledSelectPrimitive
                  control={formMethods.control}
                  name="frequency.interval"
                  label="Every"
                  options={everyHourlyOrMinutelyOptions}
                  optionIdKey="value"
                  optionLabelKey="label"
                  rules={{ required: true }}
                />
              </Grid>
              <PeriodTimeInput
                formMethods={formMethods}
                gridBreakpoints={{
                  from: 4,
                  to: 4
                }}
                fromFieldName="frequency.execution_window_start_time"
                toFieldName="frequency.execution_window_end_time"
                fromFieldLabel="Start Time"
                toFieldLabel="End Time"
              />
            </>
          )}

          {frequency &&
            frequency !== 'one-time-task' &&
            frequency !== 'informational' &&
            startAndEndDateFields}
          {frequency !== 'prn' &&
            frequency !== 'informational' &&
            frequency !== 'twice-a-day' &&
            frequency !== 'three-times-a-day' &&
            frequency !== 'four-times-a-day' &&
            frequency !== 'five-times-a-day' &&
            frequency !== 'hourly-or-minutely' && (
              <>
                <TypeAndTimeInput
                  fieldNames={{
                    startTime: 'frequency.execution_window_start_time',
                    endTime: 'frequency.execution_window_end_time',
                    type: 'frequency.type',
                    partOfDay: 'frequency.partOfDay',
                    numberOfDoseUnits: 'frequency.number_of_dose_units'
                  }}
                  gridBreakpoints={{
                    from: 4,
                    to: 4
                  }}
                  formMethods={formMethods}
                />
                <Grid xs={4}>{durationInput}</Grid>
              </>
            )}
          {frequency === 'one-time-task' && (
            <>
              <Grid xs={12} md={4}>
                <ControlledDatePicker
                  control={formMethods.control}
                  label="Date of Task"
                  name="frequency.dtstart"
                  rules={{ required: true }}
                  disablePast
                />
              </Grid>
            </>
          )}
          {(frequency === 'twice-a-day' ||
            frequency === 'three-times-a-day' ||
            frequency === 'four-times-a-day' ||
            frequency === 'five-times-a-day') && (
            <>
              <Grid xs={4}>{durationInput}</Grid>
              <MultipleTimesADay
                formMethods={formMethods}
                isMedicationsDrawer={isMedicationsDrawer}
                frequencyPath="frequency"
                schedulePath="schedule"
              />
            </>
          )}
          {frequency === 'hourly-or-minutely' && (
            <Grid xs={12}>{durationInput}</Grid>
          )}
        </>
      )}
    </Grid>
  );
};
