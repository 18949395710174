if (import.meta.env.MODE === 'production') {
  (function (w, u, d) {
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://code.upscope.io/Hja7NQqU7o.js';
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (typeof u !== 'function') {
      w.Upscope = i;
      l();
    }
  })(window, window.Upscope, document);

  window.Upscope('init');
}
