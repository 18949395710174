import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import { selectedFacilityIdAtom } from '@/hooks/useFacilitiesQuery';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { ResidentModel } from '@/models/ResidentModel';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

interface ResidentStatusContextType {
  resident: ResidentModel | undefined;
  isFetchingResident: boolean | null;
  refetch: () => Promise<any> | null;
  isResidentArchived: boolean;
}

interface ResidentStatusContextProps {
  children: React.ReactNode;
}

export const ResidentStatusContext = createContext<ResidentStatusContextType>(
  {} as ResidentStatusContextType
);

export const ResidentStatusProvider: React.FC<ResidentStatusContextProps> = ({
  children
}) => {
  const facilityId = useAtomValue(selectedFacilityIdAtom);
  const residentId = useParams().resident_id!;
  const {
    data: resident,
    isFetching: isFetchingResident,
    refetch
  } = useFindOneResidentQuery({
    residentId,
    facilityId: facilityId!
  });

  return (
    <ResidentStatusContext.Provider
      value={{
        resident,
        isFetchingResident,
        refetch,
        isResidentArchived:
          resident?.isResidentArchived! &&
          FeatureFlagService.isEnabled(ExacareFeature.ARCHIVED_RESIDENTS)
      }}>
      {children}
    </ResidentStatusContext.Provider>
  );
};
