import {
  Configuration as Assessmentsv2Configuration,
  ConfigurationParameters,
  DefaultApi as Assessmentsv2Api
} from '@/types/assessmentsv2';
import {
  Configuration as Billingv2Configuration,
  DefaultApi as Billingv2Api
} from '@/types/billingv2';
import {
  Configuration as Billingv2ApiConfiguration,
  DefaultApi as Billingv2ApiApi
} from '@/types/billingv2-api';
import {
  Configuration as CarePlanConfiguration,
  DefaultApi as CarePlanApi
} from '@/types/care-plan';
import {
  Configuration as CRMConfiguration,
  DefaultApi as CRMApi
} from '@/types/crm';
import {
  Configuration as CrmCampaignsConfiguration,
  DefaultApi as CrmCampaignsApi
} from '@/types/crm-campaigns';
import {
  Configuration as CrmScreenerApiConfiguration,
  DefaultApi as CrmScreenerApi
} from '@/types/crm-screener';
import {
  Configuration as DataCenterConfiguration,
  DefaultApi as DataCenterApi
} from '@/types/data-center';
import {
  Configuration as Incidentsv2Configuration,
  DefaultApi as Incidentsv2Api
} from '@/types/incidentsv2';
import {
  Configuration as MedicationsConfiguration,
  DefaultApi as MedicationsApi
} from '@/types/medications';
import {
  Configuration as NotificationsConfiguration,
  DefaultApi as NotificationsApi
} from '@/types/notifications';
import {
  Configuration as PharmacyIntegrationConfiguration,
  DefaultApi as PharmacyIntegration
} from '@/types/pharmacy-integration';
import {
  Configuration as ResidentResourcesConfiguration,
  DefaultApi as ResidentResourcesIntegration
} from '@/types/resident-resources';
import {
  Configuration as ResidentConfiguration,
  DefaultApi as ResidentIntegration
} from '@/types/residents';
import {
  Configuration as UsersConfiguration,
  DefaultApi as UsersApi
} from '@/types/users';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { newVersionMiddleware } from './newVersionMiddleware';
import { sanitizeStrings } from './sanitizeStrings';

function setupConfigurationParams(basePath: string): ConfigurationParameters {
  return {
    basePath,
    middleware: [
      {
        pre: async (context) => {
          const accessToken = await window.getAccessTokenSilently({
            audience: import.meta.env.VITE_AUTH0_AUDIENCE
          });
          context.init.headers = {
            ...context.init.headers,
            Authorization: `Bearer ${accessToken}`,
            'x-fe-page': window.location.href
          };
          return context;
        },
        post: FeatureFlagService.isEnabled(ExacareFeature.VERSION_CHECK)
          ? async (context) => newVersionMiddleware(context.response)
          : undefined
      },
      {
        pre: async (context) => {
          if (context.init.skipStringSanitize) {
            return context;
          }
          // Skip sanitization for FormData for now
          if (typeof context.init.body === 'string') {
            const bodyAsJson = JSON.parse(context.init.body);
            context.init.body = JSON.stringify(sanitizeStrings(bodyAsJson));
          }
          return context;
        }
      }
    ]
  };
}

export const assessmentsv2Client = new Assessmentsv2Api(
  new Assessmentsv2Configuration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_ASSESSMENTS_V2)
  )
);

export const billingv2Client = new Billingv2Api(
  new Billingv2Configuration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_BILLING_V2)
  )
);

export const billingv2ApiClient = new Billingv2ApiApi(
  new Billingv2ApiConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_BILLING_V2_API)
  )
);

export const dataCenterClient = new DataCenterApi(
  new DataCenterConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_DATA_CENTER)
  )
);

export const incidentsv2Client = new Incidentsv2Api(
  new Incidentsv2Configuration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_INCIDENTS_V2)
  )
);

export const notificationsClient = new NotificationsApi(
  new NotificationsConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_NOTIFICATIONS)
  )
);

export const medicationsClient = new MedicationsApi(
  new MedicationsConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_MEDICATION)
  )
);

export const carePlanClient = new CarePlanApi(
  new CarePlanConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_CARE_PLAN)
  )
);

export const usersv2Client = new UsersApi(
  new UsersConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_USERS)
  )
);

export const pharmacyClient = new PharmacyIntegration(
  new PharmacyIntegrationConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_PHARMACY)
  )
);

export const CRMClient = new CRMApi(
  new CRMConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_CRM)
  )
);

export const residentsClient = new ResidentIntegration(
  new ResidentConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_RESIDENTS)
  )
);

export const residentsResourcesClient = new ResidentResourcesIntegration(
  new ResidentResourcesConfiguration(
    setupConfigurationParams(
      import.meta.env.VITE_API_BASE_PATH_RESIDENT_RESOURCES
    )
  )
);

export const crmCampaignsClient = new CrmCampaignsApi(
  new CrmCampaignsConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_CRM_CAMPAIGNS)
  )
);

export const crmScreenerClient = new CrmScreenerApi(
  new CrmScreenerApiConfiguration(
    setupConfigurationParams(import.meta.env.VITE_API_BASE_PATH_CRM_SCREENER)
  )
);
