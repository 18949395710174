import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { CrmProspectResidentLoader } from '../middleware/CrmProspectResidentLoader';

const ResidentFacesheet = loadable(() => import("@/components/ResidentFacesheet")); // prettier-ignore
const ResidentRecords = loadable(() => import("@/pages/ResidentPage/ResidentRecordsPage")); // prettier-ignore
const ResidentAssessmentsPage = loadable(() => import("@/pages/ResidentPage/ResidentAssessmentsPage/ResidentAssessmentsPage")); // prettier-ignore
const ResidentAssessmentsCompletedPage = loadable(() => import("@/pages/ResidentPage/ResidentAssessmentsPage/ResidentAssessmentsCompletedGrid")); // prettier-ignore
const CRMProspectsPage = loadable(() => import("@/pages/CRM/ProspectsPage")); // prettier-ignore
const CRMProspectsResidentsPage = loadable(() => import("@/pages/CRM/ProspectsPage/ProspectResidents")); // prettier-ignore
const CRMResidentsWaitlistPage = loadable(() => import("@/pages/CRM/ProspectsPage/ResidentWaitlist")); // prettier-ignore
const CRMProspectsDepositsPage = loadable(() => import("@/pages/CRM/ProspectsPage/ProspectsDeposits")); // prettier-ignore
const CRMResidentPage = loadable(() => import("@/pages/CRM/ResidentPage")); // prettier-ignore
const CRMResidentNotesPage = loadable(() => import("@/pages/CRM/ResidentPage/ResidentNotesPage")); // prettier-ignore
const CRMResidentTasksPage = loadable(() => import("@/pages/CRM/ResidentPage/ResidentTasksPage")); // prettier-ignore
const CRMResidentHistoryPage = loadable(() => import("@/pages/CRM/ResidentPage/ResidentHistoryPage")); // prettier-ignore
const CRMResidentCallsPage = loadable(() => import("@/pages/CRM/ResidentPage/ResidentCallsPage")); // prettier-ignore
const CRMCallReviewPage = loadable(() => import("@/pages/CRM/CallReviewPage")); // prettier-ignore
const CRMResidentTextsPage = loadable(() => import("@/pages/CRM/ResidentPage/ResidentTextsPage")); // prettier-ignore
const CRMTasksPage = loadable(() => import("@/pages/CRM/TasksPage")); // prettier-ignore

export enum CRMProspectRoutes {
  CRMProspects = '/crm/prospects',
  CRMProspectsResidents = '/crm/prospects/residents',
  CRMResidentsWaitlist = '/crm/prospects/resident-waitlist',
  CRMProspectsDeposits = '/crm/prospects/deposits',
  CRMResident = '/crm/resident/:resident_id',
  CRMResidentCalls = '/crm/resident/:resident_id/calls',
  CRMResidentTexts = '/crm/resident/:resident_id/texts',
  CRMResidentFacesheet = '/crm/resident/:resident_id/facesheet',
  CRMResidentTasks = '/crm/resident/:resident_id/tasks',
  CRMResidentNotes = '/crm/resident/:resident_id/notes',
  CRMResidentRecords = '/crm/resident/:resident_id/records',
  CRMResidentHistory = '/crm/resident/:resident_id/history',
  CRMResidentAssessments = '/crm/resident/:resident_id/assessments',
  CRMResidentAssessmentsCompleted = '/crm/resident/:resident_id/assessments/completed',
  CRMTasks = '/crm/tasks',
  CRMTasksWithInstanceIdAndDate = '/crm/tasks/:task_instance_id/:task_date',
  CRMCallReview = '/crm/calls/:call_id'
}

export const getCrmProspectRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return [
    {
      path: CRMProspectRoutes.CRMProspects,
      element: <CRMProspectsPage />,
      children: [
        {
          path: CRMProspectRoutes.CRMProspectsResidents,
          element: <CRMProspectsResidentsPage />
        },
        {
          path: CRMProspectRoutes.CRMResidentsWaitlist,
          element: <CRMResidentsWaitlistPage />
        },
        ...(currentUser.isBillingEnabledForUserAndOrg() &&
        FeatureFlagService.isEnabled(ExacareFeature.BILLING_V2) &&
        FeatureFlagService.isEnabled(
          ExacareFeature.BILLING_DEPOSITS_AND_CREDITS
        )
          ? [
              {
                path: CRMProspectRoutes.CRMProspectsDeposits,
                element: <CRMProspectsDepositsPage />
              }
            ]
          : [])
      ]
    },
    {
      path: CRMProspectRoutes.CRMResident,
      element: (
        <CrmProspectResidentLoader>
          <CRMResidentPage />
        </CrmProspectResidentLoader>
      ),
      children: [
        {
          path: CRMProspectRoutes.CRMResidentFacesheet,
          element: <ResidentFacesheet isCRM />
        },
        {
          path: CRMProspectRoutes.CRMResidentNotes,
          element: <CRMResidentNotesPage />
        },
        {
          path: CRMProspectRoutes.CRMResidentTasks,
          element: <CRMResidentTasksPage />
        },
        {
          path: CRMProspectRoutes.CRMResidentRecords,
          element: <ResidentRecords />
        },
        {
          path: CRMProspectRoutes.CRMResidentAssessments,
          element: <ResidentAssessmentsPage />
        },
        {
          path: CRMProspectRoutes.CRMResidentAssessmentsCompleted,
          element: <ResidentAssessmentsCompletedPage />
        },
        ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_ACTIVITY_LOG)
          ? [
              {
                path: CRMProspectRoutes.CRMResidentHistory,
                element: <CRMResidentHistoryPage />
              }
            ]
          : []),
        ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_RESIDENT_CALLS)
          ? [
              {
                path: CRMProspectRoutes.CRMResidentCalls,
                element: <CRMResidentCallsPage />
              }
            ]
          : []),
        ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_RESIDENT_TEXTS)
          ? [
              {
                path: CRMProspectRoutes.CRMResidentTexts,
                element: <CRMResidentTextsPage />
              }
            ]
          : [])
      ]
    },
    ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_RESIDENT_CALLS)
      ? [
          {
            path: CRMProspectRoutes.CRMCallReview,
            element: <CRMCallReviewPage />
          }
        ]
      : []),
    {
      path: CRMProspectRoutes.CRMTasks,
      element: <CRMTasksPage />
    },
    {
      path: CRMProspectRoutes.CRMTasksWithInstanceIdAndDate,
      element: <CRMTasksPage />
    }
  ];
};
