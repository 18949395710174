import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const CRMAdminHubPage = loadable(() => import("@/pages/CRM/AdminHubPage")); // prettier-ignore
const CRMAdminHubPageDashboard = loadable(() => import("@/pages/CRM/AdminHubPage/AdminHubDashboard")); // prettier-ignore
const CRMDataCenterDashboards = loadable(() => import("@/pages/DataCenter/DashboardTab")); // prettier-ignore
const CRMDataCenterViewDashboard = loadable(() => import("@/pages/DataCenter/ViewDashboard")); // prettier-ignore
const CRMDataCenterReportBuilder = loadable(() => import("@/pages/DataCenter/ReportBuilderTab")); // prettier-ignore

export enum CRMDataCenterRoutes {
  CRMDataCenterDashboards = '/crm/data-center/dashboards',
  CRMDataCenterReportBuilder = '/crm/data-center/report-builder',
  CRMDataCenterViewDashboard = '/crm/data-center/dashboards/:explo_dashboard_id',
  CRMAdminHub = '/crm/admin-hub'
}

export const getCrmDataCenterRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return [
    ...(!FeatureFlagService.isEnabled(ExacareFeature.DATA_CENTER_TAB) &&
    currentUser.isDataCenterEnabled()
      ? [
          {
            path: CRMDataCenterRoutes.CRMAdminHub,
            element: <CRMAdminHubPage />,
            children: [
              {
                path: CRMDataCenterRoutes.CRMAdminHub,
                element: <CRMAdminHubPageDashboard />
              }
            ]
          }
        ]
      : []),
    ...(FeatureFlagService.isEnabled(ExacareFeature.DATA_CENTER_TAB) &&
    currentUser.isDataCenterEnabled()
      ? [
          {
            path: CRMDataCenterRoutes.CRMDataCenterDashboards,
            element: <CRMDataCenterDashboards isCRM />
          },
          {
            path: CRMDataCenterRoutes.CRMDataCenterReportBuilder,
            element: <CRMDataCenterReportBuilder isCRM />
          },
          {
            path: CRMDataCenterRoutes.CRMDataCenterViewDashboard,
            element: <CRMDataCenterViewDashboard isCRM />
          }
        ]
      : [])
  ];
};
