/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrescriptionDrugInfo } from './PrescriptionDrugInfo';
import {
    PrescriptionDrugInfoFromJSON,
    PrescriptionDrugInfoFromJSONTyped,
    PrescriptionDrugInfoToJSON,
} from './PrescriptionDrugInfo';

/**
 * 
 * @export
 * @interface PrescriptionListEntry
 */
export interface PrescriptionListEntry {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    medication_task_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    room_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    room_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    photo_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionListEntry
     */
    is_medication_task_deactivated: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    med_admin_status?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    tooltip_date_utc?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    end_date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionListEntry
     */
    has_unconfirmed_updates: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    deactivation_reason?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionListEntry
     */
    doses_remaining?: number;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionListEntry
     */
    refills_remaining?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    prescriber_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    linked_reorder_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    order_status: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionListEntry
     */
    is_receipt_confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    rx_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    order_type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionListEntry
     */
    has_dc_message?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionListEntry
     */
    is_deactivated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    pharmacy_id?: string;
    /**
     * 
     * @type {PrescriptionDrugInfo}
     * @memberof PrescriptionListEntry
     */
    drug_info?: PrescriptionDrugInfo;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionListEntry
     */
    pharm_order_message_id: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionListEntry
     */
    amount_in_stock?: number;
}

/**
 * Check if a given object implements the PrescriptionListEntry interface.
 */
export function instanceOfPrescriptionListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "is_medication_task_deactivated" in value;
    isInstance = isInstance && "has_unconfirmed_updates" in value;
    isInstance = isInstance && "order_status" in value;
    isInstance = isInstance && "pharm_order_message_id" in value;

    return isInstance;
}

export function PrescriptionListEntryFromJSON(json: any): PrescriptionListEntry {
    return PrescriptionListEntryFromJSONTyped(json, false);
}

export function PrescriptionListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'medication_task_id': !exists(json, 'medication_task_id') ? undefined : json['medication_task_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'room_id': !exists(json, 'room_id') ? undefined : json['room_id'],
        'room_number': !exists(json, 'room_number') ? undefined : json['room_number'],
        'photo_url': !exists(json, 'photo_url') ? undefined : json['photo_url'],
        'is_medication_task_deactivated': json['is_medication_task_deactivated'],
        'med_admin_status': !exists(json, 'med_admin_status') ? undefined : json['med_admin_status'],
        'tooltip_date_utc': !exists(json, 'tooltip_date_utc') ? undefined : json['tooltip_date_utc'],
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'has_unconfirmed_updates': json['has_unconfirmed_updates'],
        'deactivation_reason': !exists(json, 'deactivation_reason') ? undefined : json['deactivation_reason'],
        'doses_remaining': !exists(json, 'doses_remaining') ? undefined : json['doses_remaining'],
        'refills_remaining': !exists(json, 'refills_remaining') ? undefined : json['refills_remaining'],
        'prescriber_name': !exists(json, 'prescriber_name') ? undefined : json['prescriber_name'],
        'linked_reorder_number': !exists(json, 'linked_reorder_number') ? undefined : json['linked_reorder_number'],
        'order_status': json['order_status'],
        'is_receipt_confirmed': !exists(json, 'is_receipt_confirmed') ? undefined : json['is_receipt_confirmed'],
        'rx_number': !exists(json, 'rx_number') ? undefined : json['rx_number'],
        'order_type': !exists(json, 'order_type') ? undefined : json['order_type'],
        'has_dc_message': !exists(json, 'has_dc_message') ? undefined : json['has_dc_message'],
        'is_deactivated': !exists(json, 'is_deactivated') ? undefined : json['is_deactivated'],
        'pharmacy_id': !exists(json, 'pharmacy_id') ? undefined : json['pharmacy_id'],
        'drug_info': !exists(json, 'drug_info') ? undefined : PrescriptionDrugInfoFromJSON(json['drug_info']),
        'pharm_order_message_id': json['pharm_order_message_id'],
        'amount_in_stock': !exists(json, 'amount_in_stock') ? undefined : json['amount_in_stock'],
    };
}

export function PrescriptionListEntryToJSON(value?: PrescriptionListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'medication_task_id': value.medication_task_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'room_id': value.room_id,
        'room_number': value.room_number,
        'photo_url': value.photo_url,
        'is_medication_task_deactivated': value.is_medication_task_deactivated,
        'med_admin_status': value.med_admin_status,
        'tooltip_date_utc': value.tooltip_date_utc,
        'start_date': value.start_date,
        'end_date': value.end_date,
        'has_unconfirmed_updates': value.has_unconfirmed_updates,
        'deactivation_reason': value.deactivation_reason,
        'doses_remaining': value.doses_remaining,
        'refills_remaining': value.refills_remaining,
        'prescriber_name': value.prescriber_name,
        'linked_reorder_number': value.linked_reorder_number,
        'order_status': value.order_status,
        'is_receipt_confirmed': value.is_receipt_confirmed,
        'rx_number': value.rx_number,
        'order_type': value.order_type,
        'has_dc_message': value.has_dc_message,
        'is_deactivated': value.is_deactivated,
        'pharmacy_id': value.pharmacy_id,
        'drug_info': PrescriptionDrugInfoToJSON(value.drug_info),
        'pharm_order_message_id': value.pharm_order_message_id,
        'amount_in_stock': value.amount_in_stock,
    };
}

