import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { HTTPError } from 'ky';

import { useUsersFindOneQuery } from '@/hooks/useUserQuery';
import ErrorPage from '@/pages/ErrorPage';

export const UserLoader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const userId = useParams().staff_id;
  const { error, isFetching } = useUsersFindOneQuery(userId, {
    retry: false
  });

  if (isFetching) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (error instanceof HTTPError && error.response.status === 404) {
    return (
      <ErrorPage
        title="Requested User Not Found"
        message="This user does not exist. Please check the URL and try again."
      />
    );
  }

  return <>{children}</>;
};
