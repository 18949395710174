import React from 'react';
import { Box, SxProps } from '@mui/material';
import throttle from 'lodash/throttle';

/**
 * Wrap the DataGrid component to enable in grid scrolling instead of page
 * scrolling. Make sure autoHeight prop is not set to true. We need to calculate
 * the height of the container which is the window.innerHeight subtracted by
 * any margin or height that doesn't have to do with the grid itself. It is
 * responsive such that debounced window resize events recalculate the container
 * heights.
 */
export const DataGridContainer: React.FC<{
  children: React.ReactNode;
  sx?: SxProps;
}> = ({ children, sx = {} }) => {
  React.useLayoutEffect(() => {
    calcDataGridHeight(); // calculate on initial render
    const throttled = throttle(calcDataGridHeight, 25);
    window.addEventListener('resize', throttled);
    return () => {
      window.removeEventListener('resize', throttled);
    };
  }, []);

  return (
    <Box id="data-grid-wrapper" sx={sx}>
      {children}
    </Box>
  );
};

/**
 * @param selector - the element query selecotr of the container taking up
 * vertical viewport aside from the DataGrid component ie #by-id or .class-name
 * @param cssKeys - the css keys that we should be grabbing numerical values
 * off of to increment subtraction total
 * @param buffer - the buffer to add to the final calculated height. Useful for
 * gap based calculations that can't be pulled from calculated styles
 */
interface CalcElementHeightParams {
  selector: string;
  cssKeys: Array<keyof CSSStyleDeclaration>;
  buffer?: number;
}

function calcElementHeight({
  selector,
  cssKeys,
  buffer = 0
}: CalcElementHeightParams) {
  const el = document.querySelector(selector);
  if (!el) {
    return 0;
  }
  const computedStyles = window.getComputedStyle(el!);
  return cssKeys.reduce<number>((accum, key) => {
    const valString = computedStyles[key] as string;
    // Remove non-numeric characters but keep the decimal point
    const valNumericString = valString.replace(/[^0-9.]/g, '');
    try {
      const number = Number(valNumericString);
      return number + accum;
    } catch (e) {
      console.error(
        `Error trying to calculate query selector ${selector} computed style cssKey:${String(
          key
        )} into a numeric value`
      );
    }
    return accum;
  }, buffer);
}

function calcDataGridHeight() {
  const els: CalcElementHeightParams[] = [
    {
      selector: '.MuiTabs-root',
      cssKeys: ['height']
    },
    {
      selector: '#scrollable-content',
      cssKeys: ['marginTop', 'marginBottom']
    },
    {
      selector: '#resident-details',
      cssKeys: ['marginTop', 'marginBottom', 'height']
    },
    {
      selector: '#staff-details',
      cssKeys: ['marginTop', 'marginBottom', 'height']
    },
    {
      selector: '#care-plan-toolbar',
      cssKeys: ['height']
    },
    {
      selector: '#add-resident-page',
      cssKeys: ['paddingTop', 'paddingBottom']
    },
    {
      selector: '#add-staff-page',
      cssKeys: ['paddingTop', 'paddingBottom']
    },
    {
      selector: '#stepper-header',
      cssKeys: ['height']
    },
    {
      selector: '#stepper-footer',
      cssKeys: ['height', 'marginTop']
    },
    {
      selector: '#resident-billing-top-panel',
      cssKeys: ['height', 'margin']
    },
    {
      selector: '#resident-lifecycle-header',
      cssKeys: ['height'],
      buffer: 32
    },
    {
      selector: '#referral-lifecycle-header',
      cssKeys: ['height'],
      buffer: 32
    },
    {
      selector: '#referral-details',
      cssKeys: ['height', 'marginBottom']
    },
    {
      selector: '#email-campaign-details',
      cssKeys: ['height', 'marginBottom']
    },
    {
      selector: '#resident-assessment-details',
      cssKeys: ['height'],
      buffer: 16
    },
    {
      selector: '#care-plan-review-stepper',
      cssKeys: ['height']
    },
    {
      selector: '#audience-header-details',
      cssKeys: ['height', 'marginBottom']
    },
    {
      selector: '#resident-details-mobile-app-bar',
      cssKeys: ['height']
    }
  ];
  const total = els.reduce<number>(
    (accum, params) => accum + calcElementHeight(params),
    0
  );
  const el = document.getElementById('data-grid-wrapper')!;
  if (el) {
    el.style.height = `${window.innerHeight - total}px`;
  }
}
