import React, { forwardRef } from 'react';
import { FilterAlt } from '@mui/icons-material';
import { Badge, Box, Button, ButtonProps, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

export const GridToolbarFilterButton = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function ({ sx, ...props }, ref) {
  const gridApiRef = useGridApiContext();

  const toggleFilterPanel = () =>
    gridApiRef.current.state.preferencePanel.open
      ? gridApiRef.current.hideFilterPanel()
      : gridApiRef.current.showFilterPanel();

  return (
    <Tooltip
      title={
        gridApiRef.current.state.preferencePanel.open
          ? 'Hide filters'
          : 'Show filters'
      }
      enterDelay={1000}>
      <Badge
        color="primary"
        badgeContent={gridApiRef.current.state.filter.filterModel.items.length}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        sx={{ '& .MuiBadge-badge': { left: 39, top: 9 } }}>
        <Button
          ref={ref}
          variant="contained"
          color="secondary"
          startIcon={<FilterAlt />}
          onClick={toggleFilterPanel}
          sx={{
            px: { xs: '11px', xl: '22px' },
            '& .MuiButton-startIcon': {
              margin: { xs: '0', xl: '0 8px 0 -4px' }
            },
            ...sx
          }}
          {...props}>
          <Box
            component="span"
            sx={{ display: { xs: 'none', xl: 'inline-block' } }}>
            Filters
          </Box>
        </Button>
      </Badge>
    </Tooltip>
  );
});

GridToolbarFilterButton.displayName = 'GridToolbarFilterButton';
