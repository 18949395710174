import React, { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { TotalDosage } from '@/components/TotalDosage';
import { useCustomMedicationsQuery } from '@/hooks/useCustomMedicationsQuery';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';

interface NumberOfDoseUnitsProps {
  formMethods: UseFormReturn<any>;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberOfDoseUnits: React.FC<NumberOfDoseUnitsProps> = ({
  formMethods,
  name,
  onChange
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const [dose, numberOfDoseUnits, medication_id, fdbDispensableDrug] =
    formMethods.watch(['dose', name, 'medication_id', 'DispensableDrug']);
  const { data: customMedication } =
    useCustomMedicationsQuery().findOne(medication_id);

  const { label, required, disabled } = React.useMemo(() => {
    if (name === 'non_community_dose') {
      return {
        label: 'Doses',
        required: false,
        disabled: false
      };
    }
    return {
      label: dose?.id === null ? 'Doses N/A' : 'Doses',
      required: dose?.id !== null,
      disabled: dose?.id === null
    };
  }, [dose, name]);

  const inputDisabled = disabled || isResidentArchived;

  return (
    <>
      <div style={{ width: '100%' }} />
      <Grid xs={12} md={3}>
        <ControlledTextField
          control={formMethods.control}
          label={label}
          disabled={inputDisabled}
          name={name}
          rules={{
            min: 0.01,
            required
          }}
          type="number"
          placeholder="1"
          inputProps={{
            step: 0.01,
            min: 0.01
          }}
          onChange={onChange}
          maxDecimalPlaces={2}
        />
      </Grid>
      <Grid xs={12} md={9} data-testid="total-dosage-alert" alignSelf="center">
        <TotalDosage
          dispensable={fdbDispensableDrug}
          numberOfDoseUnits={numberOfDoseUnits}
          customMedication={customMedication}
        />
      </Grid>
    </>
  );
};
