import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { UserLoader } from '../middleware/UserLoader';
import { ProtectedRoute } from '../ProtectedRoute';

const StaffFacesheet = loadable(() => import("@/pages/StaffPage/StaffFacesheet")); // prettier-ignore
const StaffOverviewDataGrid = loadable(() => import("@/pages/StaffOverviewPage/StaffOverviewDataGrid")); // prettier-ignore
const StaffOverviewPage = loadable(() => import("@/pages/StaffOverviewPage")); // prettier-ignore
const StaffSystemPermissions = loadable(() => import("@/pages/StaffPage/StaffSystemPermissions")); // prettier-ignore
const StaffUserSettings = loadable(() => import("@/pages/StaffPage/StaffUserSettings.tsx")); // prettier-ignore
const StaffPage = loadable(() => import('@/pages/StaffPage'));
const StaffPayableTotals = loadable(() => import("@/pages/StaffOverviewPage/StaffPayableTotals")); // prettier-ignore
const StaffAccess = loadable(() => import("@/pages/StaffPage/StaffAccess")); // prettier-ignore
const StaffAccessResidents = loadable(() => import("@/pages/StaffPage/StaffAccess/StaffAccessResidents")); // prettier-ignore
const StaffAccessCommunity = loadable(() => import("@/pages/StaffPage/StaffAccess/StaffAccessCommunity")); // prettier-ignore
const StaffRecords = loadable(() => import("@/pages/StaffPage/StaffRecords")); // prettier-ignore
const StaffShiftView = loadable(() => import("@/pages/StaffOverviewPage/StaffShiftView")); // prettier-ignore

export enum StaffRoutes {
  Staff = '/staff',
  StaffOverview = '/staff-overview',
  StaffShiftView = '/staff-overview/shifts',
  StaffPayableTotals = '/staff-overview/payable-totals',
  StaffFacesheet = '/staff/:staff_id/facesheet',
  StaffRecords = '/staff/:staff_id/records',
  StaffAccess = '/staff/:staff_id/access',
  StaffAccessResidents = '/staff/:staff_id/access/residents',
  StaffAccessCommunity = '/staff/:staff_id/access/community',
  SystemPermissions = '/staff/:staff_id/system-permissions',
  StaffUserSettingsPage = '/staff/:staff_id/user-settings'
}

export const getEhrStaffRoutes = (): RouteObject[] => {
  return [
    {
      path: StaffRoutes.StaffOverview,
      element: <ProtectedRoute component={StaffOverviewPage} role="L1" />,
      children: [
        {
          path: StaffRoutes.StaffOverview,
          element: <StaffOverviewDataGrid />
        },
        {
          path: StaffRoutes.StaffShiftView,
          element: <StaffShiftView />
        },
        {
          path: StaffRoutes.StaffPayableTotals,
          element: <StaffPayableTotals />
        }
      ]
    },
    {
      path: StaffRoutes.Staff,
      element: (
        <UserLoader>
          <ProtectedRoute component={StaffPage} role="L1" />
        </UserLoader>
      ),
      children: [
        {
          path: StaffRoutes.StaffFacesheet,
          element: <StaffFacesheet />
        },
        {
          path: StaffRoutes.StaffRecords,
          element: <StaffRecords />
        },
        {
          path: StaffRoutes.StaffAccess,
          element: <StaffAccess />,
          children: [
            {
              path: StaffRoutes.StaffAccessResidents,
              element: <StaffAccessResidents />
            },
            ...(FeatureFlagService.isEnabled(
              ExacareFeature.CROSS_COMMUNITY_ACCESS
            )
              ? [
                  {
                    path: StaffRoutes.StaffAccessCommunity,
                    element: <StaffAccessCommunity />
                  }
                ]
              : [])
          ]
        },
        {
          path: StaffRoutes.SystemPermissions,
          element: <StaffSystemPermissions />
        },
        ...(FeatureFlagService.isEnabled(
          ExacareFeature.VITE_FEATURE_FLAG_USER_LANDING_PREFERENCES
        )
          ? [
              {
                path: StaffRoutes.StaffUserSettingsPage,
                element: <StaffUserSettings />
              }
            ]
          : [])
      ]
    }
  ];
};
