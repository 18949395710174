import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from '@/components/Accordion';
import {
  TaskExtensionAnswer,
  TaskExtensionPayload,
  TaskExtensionQuestionType
} from '@/models/TaskExtensionModel';

interface TaskExtensionPreviewProps {
  taskExtension: TaskExtensionPayload;
  answers: TaskExtensionAnswer[];
  disabled?: boolean;
}

export default function TaskExtensionPreview(props: TaskExtensionPreviewProps) {
  const { taskExtension, answers, disabled = false } = props;
  return (
    <StyledAccordion
      expanded
      sx={{
        overflow: 'unset',
        '& .MuiButtonBase-root': { cursor: 'default !important' }
      }}>
      <StyledAccordionSummary>
        <Stack>
          <Typography fontWeight="400" fontSize="1.375em" color="secondary">
            {taskExtension.title}
          </Typography>
          <Typography fontWeight="400" fontSize="1em" color="secondary">
            {taskExtension.description}
          </Typography>
        </Stack>
      </StyledAccordionSummary>
      <StyledAccordionDetails sx={{ fontSize: '14px' }}>
        {(taskExtension.question_type === TaskExtensionQuestionType.Number ||
          taskExtension.question_type ===
            TaskExtensionQuestionType.Percentage ||
          taskExtension.question_type ===
            TaskExtensionQuestionType.FreeText) && (
          <TextField
            fullWidth
            label={taskExtension.title}
            value={answers[0].value}
            InputProps={{
              endAdornment: (
                <Typography color="#9AAEBB" fontSize="22px">
                  {taskExtension.question_type ===
                  TaskExtensionQuestionType.Percentage
                    ? '%'
                    : ''}
                </Typography>
              )
            }}
            disabled={disabled}
          />
        )}
        {taskExtension.question_type ===
          TaskExtensionQuestionType.MultipleSelect && (
          <FormGroup>
            {taskExtension.default_answer_options?.map((answer, i) => {
              const answersValues = answers.map((item) => item.value);
              return (
                <FormControlLabel
                  key={i}
                  value={answer.value}
                  control={<Checkbox />}
                  label={answer.value}
                  checked={
                    answersValues.includes(answer.value) ||
                    // Case where we need to preview and we are creating/updating
                    // the extension so the value is a boolean
                    (answersValues[i] as any) === true
                  }
                  disabled={disabled}
                />
              );
            })}
          </FormGroup>
        )}

        {taskExtension.question_type ===
          TaskExtensionQuestionType.SingleSelect && (
          <FormControl>
            <RadioGroup>
              {taskExtension.default_answer_options?.map((answer, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    value={answer.value}
                    control={<Radio />}
                    label={answer.value}
                    checked={
                      answer.value === answers[0].value ||
                      // Case where we need to preview and we are creating/updating
                      // the extension so the value is the index of the selected default answer
                      taskExtension.default_answer_options?.[
                        parseInt(answers?.[0]?.value)
                      ]?.value === answer.value
                    }
                    disabled={disabled}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
