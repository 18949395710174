// Don't export this from index because components that are rendered by react-router may
// also import below. This would result in "cannot access unintialized variable" error

import { CRMAudienceRoutes } from './getCrmRoutes/getCrmAudienceRoutes';
import { CRMCampaignRoutes } from './getCrmRoutes/getCrmCampaignRoutes';
import { CRMDataCenterRoutes } from './getCrmRoutes/getCrmDataCenterRoutes';
import { CRMInboxRoutes } from './getCrmRoutes/getCrmInboxRoutes';
import { CRMNovaContentHubRoutes } from './getCrmRoutes/getCrmNovaContentHubRoutes';
import { CRMOutboundCenterRoutes } from './getCrmRoutes/getCrmOutboundCenterRoutes';
import { CRMProspectRoutes } from './getCrmRoutes/getCrmProspectRoutes';
import { CRMReferralSourceRoutes } from './getCrmRoutes/getCrmReferralSourcesRoutes';
import { CRMScreenerRoutes } from './getCrmRoutes/getCrmScreenerRoutes';
import { AddStaffRoutes } from './getEhrRoutes/getEhrAddStaffRoutes';
import { AdminHubAssessmentRoutes } from './getEhrRoutes/getEhrAdminHubAssessmentRoutes';
import { AdminHubGeneralRoutes } from './getEhrRoutes/getEhrAdminHubGeneralRoutes';
import { AdminHubPricesProductsRoutes } from './getEhrRoutes/getEhrAdminHubPricesProductRoutes';
import { AdminHubProviderRoutes } from './getEhrRoutes/getEhrAdminHubProviderRoutes';
import { AdminHubStatementBillingCenterRoutes } from './getEhrRoutes/getEhrAdminHubStatementBillingCenterRoutes';
import { ArchivedResidentRoutes } from './getEhrRoutes/getEhrArchivedResidentRoutes';
import { EHRDataCenterRoutes } from './getEhrRoutes/getEhrDataCenterRoutes';
import { IncidentRoutes } from './getEhrRoutes/getEhrIncidentRoutes';
import { OrganizationRoutes } from './getEhrRoutes/getEhrOrganizationRoutes';
import { PharmacyRoutes } from './getEhrRoutes/getEhrPharmacyRoutes';
import { ResidentOverviewRoutes } from './getEhrRoutes/getEhrResidentOverviewRoutes';
import { ResidentRoutes } from './getEhrRoutes/getEhrResidentRoutes';
import { StaffRoutes } from './getEhrRoutes/getEhrStaffRoutes';

enum ErrorRoutes {
  Error = '/error'
}

export enum TaskRoutes {
  TasksChronological = '/tasks/chronological',
  TasksByResident = '/tasks/by-resident'
}

enum ProfileRoutes {
  Profile = '/profile',
  ProfileShifts = '/profile/shifts'
}

enum SecretRoutes {
  SecretFeatureFlagService = '/secret-feature-flag-service'
}

export const RoutePath = {
  ...ErrorRoutes,
  ...EHRDataCenterRoutes,
  ...ResidentOverviewRoutes,
  ...ResidentRoutes,
  ...ArchivedResidentRoutes,
  ...StaffRoutes,
  ...AddStaffRoutes,
  ...TaskRoutes,
  ...ProfileRoutes,
  ...AdminHubGeneralRoutes,
  ...AdminHubAssessmentRoutes,
  ...AdminHubStatementBillingCenterRoutes,
  ...AdminHubPricesProductsRoutes,
  ...AdminHubProviderRoutes,
  ...OrganizationRoutes,
  ...IncidentRoutes,
  ...CRMDataCenterRoutes,
  ...CRMProspectRoutes,
  ...CRMOutboundCenterRoutes,
  ...CRMCampaignRoutes,
  ...CRMAudienceRoutes,
  ...CRMNovaContentHubRoutes,
  ...CRMInboxRoutes,
  ...CRMReferralSourceRoutes,
  ...CRMScreenerRoutes,
  ...PharmacyRoutes,
  ...SecretRoutes
} as const;

export type RoutePath = typeof RoutePath[keyof typeof RoutePath];
