import React, { useCallback, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { FormHeader } from '@/components/FormHeader';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { TaskExtensionType } from '@/models/TaskExtensionModel';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import TaskExtensionForm, {
  InstanceWithTaskExtensionFormType
} from '../../TaskExtensions/TaskExtensionForm';

interface MedicationExtensionProps {
  // Warning: Don't type this as ResidentMedicationForm or memory usage will spike
  formMethods: UseFormReturn<any>;
  handleToTimeLink: VoidFunction;
  responsibleParties?: ResponsibleParty[];
  handleToDetailsLink: VoidFunction;
}

export default function MedicationExtension(props: MedicationExtensionProps) {
  const {
    formMethods,
    handleToTimeLink,
    responsibleParties,
    handleToDetailsLink
  } = props;

  const [extensionEnabled, isEditable] = formMethods.watch([
    'extensionEnabled',
    'is_editable'
  ]);

  const { isResidentArchived } = useContext(ResidentStatusContext);

  const isPrnFrequency = FeatureFlagService.isEnabled(
    ExacareFeature.MEDICATION_TASK_REGIMENS
  )
    ? formMethods.getValues('regimens')?.[0]?.frequency?.freq === 'prn'
    : formMethods.getValues('frequency')?.freq === 'prn';

  const responsibleParty = formMethods.getValues('responsible_party');

  const isStaffResponsibility = useCallback(isStaffResponsibilityV2, [
    responsibleParty,
    responsibleParties
  ]);

  return !isStaffResponsibility(responsibleParty, responsibleParties) ? (
    <Alert severity="info" data-testid="non-staff-party-alert-vitals">
      <AlertTitle>Non-Staff Responsible party selected</AlertTitle>
      Medication extensions are not applicable for non-staff responsible
      parties. If you would like to require custom information for a med pass,
      please select a different responsible party{' '}
      <Link href="#" onClick={handleToDetailsLink}>
        here
      </Link>
      .
    </Alert>
  ) : isPrnFrequency ? (
    <Alert severity="info">
      <AlertTitle>PRN schedule selected</AlertTitle>
      Medication extensions are not applicable for PRN medications. If you would
      like to require custom information for a med pass, please select a
      different medication schedule{' '}
      <Link href="#" onClick={handleToTimeLink}>
        here
      </Link>
      .
    </Alert>
  ) : (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <FormHeader Icon={DashboardCustomizeIcon} text="Extension" />
      </Grid>
      <Grid xs={12} md={9}>
        <Typography color="secondary" fontSize="1rem">
          Ask for extra information at task completion?
        </Typography>
      </Grid>
      <Grid xs={12} md={3} sx={{ display: 'flex', gap: 1 }}>
        <Button
          onClick={() => formMethods.setValue('extensionEnabled', true)}
          size="small"
          color={extensionEnabled ? 'primary' : 'secondary'}
          variant={'outlined'}
          disabled={isResidentArchived || !isEditable}
          sx={{ width: 60 }}>
          Yes
        </Button>
        <Button
          onClick={() => formMethods.setValue('extensionEnabled', false)}
          size="small"
          color={!extensionEnabled ? 'primary' : 'secondary'}
          disabled={isResidentArchived || !isEditable}
          variant={'outlined'}
          sx={{ width: 60 }}>
          No
        </Button>
      </Grid>
      {extensionEnabled && (
        <>
          <Grid xs={12}>
            <Typography color="secondary" fontSize="1rem">
              Select a template extension or create your own below.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TaskExtensionForm
              formMethods={
                formMethods as UseFormReturn<InstanceWithTaskExtensionFormType>
              }
              taskExtensionType={TaskExtensionType.MEDICATION_TASK}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
