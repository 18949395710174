import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { useCurrentUser } from '@/hooks/useCurrentUser';

import { RoutePath } from '../RoutePath';

import { getCrmAudienceRoutes } from './getCrmAudienceRoutes';
import { getCrmCampaignRoutes } from './getCrmCampaignRoutes';
import { getCrmDataCenterRoutes } from './getCrmDataCenterRoutes';
import { getCrmInboxRoutes } from './getCrmInboxRoutes';
import { getCrmNovaContentHubRoutes } from './getCrmNovaContentHubRoutes';
import { getCrmOutboundCenterRoutes } from './getCrmOutboundCenterRoutes';
import { getCrmProspectRoutes } from './getCrmProspectRoutes';
import { getCrmReferralSourcesRoutes } from './getCrmReferralSourcesRoutes';
import { getCrmScreenerRoutes } from './getCrmScreenerRoutes';

export const getCrmRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return currentUser.isCrmEnabled()
    ? [
        ...getCrmNovaContentHubRoutes(),
        ...getCrmProspectRoutes(),
        ...getCrmReferralSourcesRoutes(),
        ...getCrmInboxRoutes(),
        ...getCrmOutboundCenterRoutes(),
        ...getCrmAudienceRoutes(),
        ...getCrmDataCenterRoutes(),
        ...getCrmCampaignRoutes(),
        ...getCrmScreenerRoutes(),
        // Catch-all route for CRM module
        // If the URL is prefixed with /crm the user is redirected to the main CRM page
        {
          path: '/crm/*',
          element: <Navigate to={RoutePath.CRMProspectsResidents} replace />
        }
      ]
    : [];
};
