import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AvTimer } from '@mui/icons-material';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { ControlledTimeInput } from '@/components/ControlledTimeInput';
import { FormHeader } from '@/components/FormHeader';

interface PrnFollowupTaskFormProps {
  formMethods: UseFormReturn;
  type?: 'Add' | 'Edit';
  disabled?: boolean;
}

const validateFollowupTaskFormField = (
  skipFollowupTask: boolean,
  value: any
): boolean | string => {
  return (
    skipFollowupTask ||
    !!value ||
    'Both fields are required to configure followup task'
  );
};

export const PrnFollowupTaskForm: React.FC<PrnFollowupTaskFormProps> = ({
  formMethods,
  type,
  disabled = false
}) => {
  const [skipFollowupTask, setSkipFollowupTask] = React.useState(
    formMethods.getValues('followupIntervalInMinutes') == null &&
      formMethods.getValues('followupInstructions') == null &&
      type === 'Edit'
  );

  const handleSkipFollowupTask: CheckboxProps['onChange'] = (e, checked) => {
    setSkipFollowupTask(checked);

    if (checked) {
      formMethods.clearErrors();
      formMethods.setValue('followupIntervalInMinutes', undefined, {
        shouldDirty: true
      });
      formMethods.setValue('followupInstructions', undefined, {
        shouldDirty: true
      });
    } else {
      formMethods.setValue('followupIntervalInMinutes', 60, {
        shouldDirty: true
      });
    }
  };

  return (
    <Stack spacing={2}>
      <FormHeader
        Icon={AvTimer}
        text="PRN Follow-Up Care Task"
        fontSize="18px"
      />
      <Typography color="#000000" fontWeight={400} fontSize="16px">
        You can set a PRN follow-up care task here so that your staff are
        reminded to check on the result of the PRN administration. They will be
        prompted to fill in a box detailing the result.
      </Typography>
      <FormGroup>
        <FormControlLabel
          data-testid="skip-followup-task-checkbox"
          control={
            <Checkbox
              checked={skipFollowupTask}
              onChange={handleSkipFollowupTask}
            />
          }
          label="Skip the followup task"
          disabled={disabled}
        />
      </FormGroup>
      <ControlledTimeInput
        name="followupIntervalInMinutes"
        control={formMethods.control}
        step={15}
        type={'Minutes'}
        label="How many minutes after the PRN?"
        max={1440}
        disabled={skipFollowupTask || disabled}
        validate={(value) =>
          validateFollowupTaskFormField(skipFollowupTask, value)
        }
      />
      <ControlledTextField
        name="followupInstructions"
        control={formMethods.control}
        label="Directions for follow up task"
        multiline
        disabled={skipFollowupTask || disabled}
        rules={{
          validate: (value) =>
            validateFollowupTaskFormField(skipFollowupTask, value)
        }}
      />
    </Stack>
  );
};
