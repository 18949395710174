import { EmailAddressBookItem } from '@/types/crm';

export class EmailAddressBookItemModel implements EmailAddressBookItem {
  facility_id: string;
  resource_id: string;
  first_name?: string;
  last_name?: string;
  email: string;
  photo_url?: string;
  type: string;
  constructor(payload: EmailAddressBookItem) {
    this.facility_id = payload.facility_id;
    this.resource_id = payload.resource_id;
    this.first_name = payload.first_name;
    this.last_name = payload.last_name;
    this.email = payload.email;
    this.photo_url = payload.photo_url;
    this.type = payload.type;
  }
  public getFullName = (): string => {
    const { first_name, last_name } = this;
    if (!first_name) return 'Unknown';
    return `${first_name} ${last_name}`;
  };

  public getInitials = (): string =>
    `${this.first_name?.charAt(0) || ''}${this.last_name?.charAt(0) || ''}`;
}
