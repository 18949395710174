import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { Add, Delete } from '@mui/icons-material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { TaskExtensionQuestionType } from '@/models/TaskExtensionModel';

import { InstanceWithTaskExtensionFormType } from '.';

interface TaskExtensionMultiChoiceFormProps {
  formMethods: UseFormReturn<InstanceWithTaskExtensionFormType>;
  cleanTemplateSelect: VoidFunction;
  questionType: TaskExtensionQuestionType;
  resetFirstDefaultAnswer: VoidFunction;
  disabled?: boolean;
}

export default function TaskExtensionMultiChoiceForm(
  props: TaskExtensionMultiChoiceFormProps
) {
  const {
    formMethods,
    cleanTemplateSelect,
    questionType,
    resetFirstDefaultAnswer,
    disabled = false
  } = props;

  const {
    fields: defaultAnswerOptionFields,
    append: appendDefaultAnswerOptionField,
    remove: removeDefaultAnswerOptionField
  } = useFieldArray({
    control: formMethods.control,
    name: 'task_extension.default_answer_options'
  });

  const { remove: removeDefaultAnswerField } = useFieldArray({
    control: formMethods.control,
    name: 'task_extension.default_answers'
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Grid container height={1}>
            {defaultAnswerOptionFields.map((field, index) => {
              return (
                <Grid
                  item
                  mt={2}
                  gap={1}
                  key={field.id}
                  xs={12}
                  display="flex"
                  alignItems="center">
                  <SubdirectoryArrowRightIcon
                    sx={{
                      color: '#9AAEBB'
                    }}
                  />
                  <ControlledTextField
                    disabled={disabled}
                    control={formMethods.control}
                    name={`task_extension.default_answer_options.${index}.value`}
                    label={`Answer #${index + 1}`}
                    rules={{ required: true }}
                    onChange={() => cleanTemplateSelect()}
                    InputProps={{
                      ...(questionType ===
                        TaskExtensionQuestionType.SingleSelect &&
                        formMethods
                          .getValues('task_extension.default_answers')
                          ?.find((item) => item?.value === index.toString()) !==
                          undefined && {
                          endAdornment: (
                            <Typography color="#9AAEBB" fontSize="16px">
                              Default
                            </Typography>
                          )
                        }),
                      ...(questionType ===
                        TaskExtensionQuestionType.MultipleSelect &&
                        formMethods.getValues(
                          'task_extension.default_answers'
                        )?.[index]?.value && {
                          endAdornment: (
                            <Typography color="#9AAEBB" fontSize="16px">
                              Default
                            </Typography>
                          )
                        })
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {questionType === TaskExtensionQuestionType.SingleSelect ? (
          <Grid item xs={1}>
            <Grid container height={1}>
              <Controller
                name="task_extension.default_answers.0.value"
                control={formMethods.control}
                render={({ field: { onChange, ...field } }) => (
                  <FormControl>
                    <RadioGroup
                      sx={{
                        height: 1
                      }}
                      onChange={(e) => {
                        const { value } = e.target;
                        onChange?.(value);
                      }}
                      {...field}
                      row>
                      {defaultAnswerOptionFields.map((field, index) => {
                        return (
                          <Grid
                            item
                            mt={2}
                            gap={1}
                            key={field.id}
                            xs={12}
                            display="flex"
                            alignItems="center">
                            <Radio
                              disabled={disabled}
                              key={index}
                              value={index}
                              checked={
                                formMethods
                                  .getValues('task_extension.default_answers')
                                  ?.find(
                                    (item) => item?.value === index.toString()
                                  ) !== undefined
                              }
                            />
                          </Grid>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={1}>
            <Grid container height={1}>
              {defaultAnswerOptionFields.map((field, index) => {
                return (
                  <Controller
                    key={field.id}
                    control={formMethods.control}
                    name={`task_extension.default_answers.${index}.value`}
                    render={({ field }) => {
                      return (
                        <Grid
                          item
                          mt={2}
                          gap={1}
                          xs={12}
                          display="flex"
                          alignItems="center">
                          <Checkbox
                            {...field}
                            value={field.value}
                            checked={
                              typeof field.value === 'boolean'
                                ? field.value
                                : false
                            }
                          />
                        </Grid>
                      );
                    }}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid item xs={1}>
          <Grid container height={1}>
            {defaultAnswerOptionFields.map((field, index) => {
              return (
                <Grid
                  item
                  mt={2}
                  gap={1}
                  key={field.id}
                  xs={12}
                  display="flex"
                  alignItems="center">
                  {index !== 0 ? (
                    <Avatar
                      onClick={() => {
                        removeDefaultAnswerOptionField(index);
                        if (
                          questionType ===
                          TaskExtensionQuestionType.MultipleSelect
                        ) {
                          removeDefaultAnswerField(index);
                        } else if (
                          questionType ===
                          TaskExtensionQuestionType.SingleSelect
                        ) {
                          if (
                            formMethods.getValues(
                              'task_extension.default_answers.0.value'
                            ) === index.toString()
                          ) {
                            resetFirstDefaultAnswer();
                          }
                        }
                        cleanTemplateSelect();
                      }}
                      sx={(theme) => ({
                        background: theme.palette.info.main,
                        color: '#667A86',
                        width: 32,
                        height: 32,
                        ':hover': {
                          cursor: 'pointer'
                        }
                      })}>
                      <Delete />
                    </Avatar>
                  ) : (
                    <Box height="32px" />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={() => {
          appendDefaultAnswerOptionField({
            value: ''
          });
          cleanTemplateSelect();
        }}
        sx={{ width: 'max-content' }}>
        Answer
      </Button>
    </>
  );
}
