import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ProtectedRoute } from '../ProtectedRoute';

const AdminHubInformation = loadable(() => import("@/pages/Organization/OrganizationInformation")); // prettier-ignore
const AdminHubFacilities = loadable(() => import("@/pages/Organization/OrganizationFacilities")); // prettier-ignore
const CommunitySettings = loadable(() => import("@/components/CommunitySettings")); // prettier-ignore
const Organization = loadable(() => import("@/pages/Organization")); // prettier-ignore
const OrganizationDoctorContacts = loadable(() => import("@/pages/Organization/OrganizationDoctorContacts")); // prettier-ignore
const OrganizationFuneralHomes = loadable(() => import("@/pages/Organization/OrganizationFuneralHomes")); // prettier-ignore
const OrganizationHospitals = loadable(() => import("@/pages/Organization/OrganizationHospitals")); // prettier-ignore
const OrganizationOtherProviders = loadable(() => import("@/pages/Organization/OrganizationOtherProviders")); // prettier-ignore
const OrganizationPharmacies = loadable(() => import("@/pages/Organization/OrganizationPharmacies")); // prettier-ignore

export enum OrganizationRoutes {
  Organization = '/organization',
  OrganizationCommunities = '/organization/communities',
  OrganizationCommunity = '/organization/community/:facility_id',
  OrganizationInformation = '/organization/information',
  OrganizationDoctorContacts = '/organization/doctor-contacts',
  OrganizationPharmacies = '/organization/pharmacies',
  OrganizationHospitals = '/organization/hospitals',
  OrganizationFuneralHomes = '/organization/funeral-homes',
  OrganizationOtherProviders = '/organization/other-providers'
}

export const getEhrOrganizationRoutes = (): RouteObject[] => {
  return [
    {
      path: OrganizationRoutes.Organization,
      element: <ProtectedRoute component={Organization} role="L1" />,
      children: [
        {
          path: OrganizationRoutes.OrganizationCommunities,
          element: <AdminHubFacilities navigateToAdminHubCommunities={false} />
        },
        {
          path: OrganizationRoutes.OrganizationCommunity,
          element: <CommunitySettings />
        },
        {
          path: OrganizationRoutes.OrganizationInformation,
          element: <AdminHubInformation />
        },
        {
          path: OrganizationRoutes.OrganizationDoctorContacts,
          element: <OrganizationDoctorContacts />
        },
        {
          path: OrganizationRoutes.OrganizationPharmacies,
          element: <OrganizationPharmacies />
        },
        {
          path: OrganizationRoutes.OrganizationHospitals,
          element: <OrganizationHospitals />
        },
        {
          path: OrganizationRoutes.OrganizationFuneralHomes,
          element: <OrganizationFuneralHomes />
        },
        {
          path: OrganizationRoutes.OrganizationOtherProviders,
          element: <OrganizationOtherProviders />
        }
      ]
    }
  ];
};
