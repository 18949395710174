import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useSetAtom } from 'jotai';
import { debounce } from 'lodash';

import ControlledAutoComplete from '@/components/ControlledAutoComplete';
import {
  FIND_ALL_CUSTOM_MEDICATIONS_DEFAULT_LIMIT,
  useCustomMedicationsQuery
} from '@/hooks/useCustomMedicationsQuery';
import { selectedFacilityIdAtom } from '@/hooks/useFacilitiesQuery';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  CustomMedicationModel,
  customMedicationsAutocompleteFilterOptions
} from '@/pages/CRM/models/CustomMedicationModel';
import { RoutePath } from '@/routes/RoutePath';

import { showCreateQuickCustomMedicationDialog } from './CreateQuickCustomMedicationDialog';

interface SelectCustomDrugQuickCreateProps {
  formMethods: UseFormReturn<any>;
  onChange: (newValue?: string) => void;
  disabled: boolean;
  residentId?: string;
}

export const SelectCustomDrugQuickCreate: React.FC<
  SelectCustomDrugQuickCreateProps
> = ({ formMethods, onChange, disabled, residentId }) => {
  const { data: resident, isLoading: isLoadingResident } =
    useFindOneResidentQuery(
      {
        residentId: residentId!
      },
      { enabled: !!residentId }
    );

  const containerRef = useRef<HTMLDivElement>(null);
  const [searchParam, setSearchParam] = useState<string>('');
  const [selectedCustomMedication, setSelectedCustomMedication] =
    useState<CustomMedicationModel>();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    CustomMedicationModel[]
  >([]);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const findAllCustomMedicationsQuery =
    useCustomMedicationsQuery().findAllInfinite({
      facility_id: resident?.facility_id,
      limit: FIND_ALL_CUSTOM_MEDICATIONS_DEFAULT_LIMIT,
      search: searchParam,
      options: {
        onError: () => {
          showSnackbar({
            message: 'Error loading messages',
            severity: 'error'
          });
        }
      }
    });

  const {
    data: customMedications,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isLoadingCustomMedications
  } = findAllCustomMedicationsQuery;

  useEffect(() => {
    if (customMedications) {
      const allCustomMedications = [];
      for (const page of customMedications.pages) {
        if (page?.data) {
          allCustomMedications.push(...page.data);
        }
      }
      setAutoCompleteOptions(allCustomMedications);
    }
  }, [customMedications]);

  const onCustomMedicationInputChange = useCallback((newValue: string) => {
    debouncedSetSearchParam(newValue);
  }, []);

  const debouncedSetSearchParam = useCallback(
    debounce((newValue) => {
      setSearchParam(newValue);
    }, 1000),
    []
  );

  const handleScroll: React.UIEventHandler<HTMLUListElement> = (e) => {
    if (isFetchingNextPage || !hasNextPage) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 25) {
      findAllCustomMedicationsQuery.fetchNextPage();
    }
  };

  const selectedMedicationId = formMethods.watch('medication_id');

  useEffect(() => {
    if (selectedMedicationId) {
      setSearchParam(formMethods.getValues('DispensableDrug.DrugNameDesc'));
    }
  }, [selectedMedicationId]);

  useEffect(() => {
    if (selectedMedicationId && autoCompleteOptions.length) {
      const possibleSelectedMedication = autoCompleteOptions.find(
        (item) => item.id === selectedMedicationId
      );
      if (!possibleSelectedMedication) return;
      setSelectedCustomMedication(possibleSelectedMedication);
    }
  }, [autoCompleteOptions]);

  const setSelectedFacilityId = useSetAtom(selectedFacilityIdAtom);

  const handleCreateQuickCustomMedication = useCallback(async () => {
    const { newCustomMed } = await showCreateQuickCustomMedicationDialog({
      facilityId: resident?.facility_id
    });
    onChange(newCustomMed.id);
    setSelectedCustomMedication(newCustomMed);
    return;
  }, [resident]);

  const CustomPaperComponent: React.FC<any> = useMemo(() => {
    const CustomPaper: React.FC<any> = ({ children, loading, ...other }) => {
      return (
        <Paper {...other}>
          <Box
            sx={{
              overflowY: 'auto'
            }}
            ref={containerRef}>
            {children}
          </Box>
          {loading && (
            <Stack justifyContent="center" direction="row">
              <LinearProgress />
            </Stack>
          )}
          {isLoadingResident ? (
            <MenuItem>
              <ListItemIcon>
                <CircularProgress size="sm" sx={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText>Loading</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              onMouseDown={handleCreateQuickCustomMedication}
              sx={{
                py: '10px',
                borderBottom: '1px solid #E7EEF4',
                borderTop: '1px solid #E7EEF4'
              }}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText>Add New</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onMouseDown={async () => {
              if (!resident?.facility_id) return;
              setSelectedFacilityId(resident?.facility_id);
              navigate(
                generatePath(RoutePath.AdminHubManageCustomMedList, {
                  facility_id: resident?.facility_id
                })
              );
            }}
            sx={{ py: '10px', borderBottom: '1px solid #E7EEF4' }}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Manage Custom Med List</ListItemText>
          </MenuItem>
        </Paper>
      );
    };
    CustomPaper.displayName = 'CustomPaperComponent';
    return CustomPaper;
  }, [resident]);

  return (
    <Grid xs={12}>
      <ControlledAutoComplete
        disabled={disabled}
        loading={isLoadingCustomMedications}
        loadingText="Searching custom medication..."
        noOptionsText={`No custom medication found for "${searchParam}"`}
        onInputChange={(e, newValue) => onCustomMedicationInputChange(newValue)}
        control={formMethods.control}
        name={'medication_id'}
        label="Medication"
        options={autoCompleteOptions}
        filterOptions={customMedicationsAutocompleteFilterOptions}
        getOptionLabel={(option) => option.name}
        getOptionKey={(option) => option.id}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderOption={(props, option: CustomMedicationModel) => (
          <MenuItem {...props} key={option.id} value={option.id}>
            {option.renderListItem()}
          </MenuItem>
        )}
        value={selectedCustomMedication || null}
        onChange={(e, data) => {
          onChange();
          formMethods.setValue('medication_id', data?.id!, {
            shouldDirty: true
          });
          setSelectedCustomMedication(data);
        }}
        ListboxProps={{
          onScroll: handleScroll,
          sx: {
            maxHeight: '250px',
            overflowY: 'auto'
          }
        }}
        componentsProps={{
          paper: {
            //@ts-ignore: loading is a custom prop for the paper component in order to show
            // a loading component in the memoized component
            loading: isFetchingNextPage
          }
        }}
        PaperComponent={CustomPaperComponent}
      />
    </Grid>
  );
};
