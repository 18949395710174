import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { Delete, ExpandMore } from '@mui/icons-material';
import {
  AccordionProps,
  IconButton,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { RRule } from 'rrule';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from '@/components/Accordion';
import { ControlledDatePicker } from '@/components/ControlledDatePicker';
import { ControlledSelectPrimitive } from '@/components/ControlledSelectPrimitive';
import { changeFrequencyHandler } from '@/components/FrequencyTimeForm/changeFrequencyHandler';
import { CustomFrequency } from '@/components/FrequencyTimeForm/CustomFrequency';
import { MultipleTimesADay } from '@/components/FrequencyTimeForm/MultipleTimesADay';
import {
  dailyIntervalOptions,
  everyHourlyOrMinutelyOptions,
  hourlyOrMinutelyFrequencyOption,
  medicationFrequencyOptions,
  monthlyIntervalOptions,
  weeklyDayOptions,
  weeklyIntervalOptions
} from '@/components/FrequencyTimeForm/options';
import PeriodTimeInput from '@/components/FrequencyTimeForm/PeriodTimeInput';
import ScheduleDoseForm from '@/components/FrequencyTimeForm/ScheduleDoseForm';
import { TypeAndTimeInput } from '@/components/FrequencyTimeForm/TypeAndTimeInput';
import { MedicationRegimenForm } from '@/stores/residentMedicationsAtom';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { NumberOfDoseUnits } from '../NumberOfDoseUnits';

import { PrnFollowupTaskForm } from './PrnFollowupTaskForm';
import { PrnLimitationsForm } from './PrnLimitationsForm';

interface MedicationRegimenProps {
  index: number;
  fieldArrayFormMethods: UseFieldArrayReturn<any, 'regimens', '_id'>;
  formMethods: UseFormReturn;
  type?: 'Add' | 'Edit';
  isStartTimeEditable?: boolean;
  disabled?: boolean;
}

export const MedicationRegimen: React.FC<MedicationRegimenProps> = ({
  index,
  fieldArrayFormMethods,
  formMethods,
  type,
  isStartTimeEditable,
  disabled = false
}) => {
  const [expanded, setExpanded] = React.useState(true);
  const [
    dtstart,
    until,
    frequency,
    slidingScaleDoseRange,
    numberOfDoseUnits,
    scheduleId
  ] = formMethods.watch([
    `regimens.${index}.frequency.dtstart`,
    `regimens.${index}.frequency.until`,
    `regimens.${index}.frequency.freq`,
    `regimens.${index}.frequency.dose_ranges`,
    `regimens.${index}.frequency.number_of_dose_units`,
    `regimens.${index}.frequency.id`
  ]);

  const formattedRegimenDate = React.useMemo(() => {
    const startDate = dayjs(dtstart);
    if (!startDate.isValid()) return '';
    const formattedStartDate = dayjs(dtstart).format('MMM D');
    const untilDate = until ? dayjs(until) : null;
    const formattedUntilDate = untilDate
      ? dayjs(until).format('[ –] MMM D')
      : '';
    const days = untilDate
      ? Math.ceil(untilDate.diff(startDate, 'days', true)) + 1
      : 0;
    const formattedDays =
      days > 0 ? ` (${days} ${pluralize('day', days)})` : '';
    return `${formattedStartDate}${formattedUntilDate}${formattedDays}`;
  }, [dtstart, until]);

  const startDatePickerDisabled =
    (type === 'Edit' && !isStartTimeEditable && scheduleId) || disabled;

  const handleSetExpanded: AccordionProps['onChange'] = (e, expanded) => {
    setExpanded(expanded);
  };

  const handleDeleteRegimen: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.stopPropagation();
    fieldArrayFormMethods.remove(index);
  };

  const handleChangeFreq = changeFrequencyHandler({
    formMethods,
    frequencyFieldPath: `regimens.${index}.frequency`,
    scheduleFieldPath: `regimens.${index}.schedule`
  });

  const validateMinDate = {
    minDate: (date: any) =>
      !date ||
      (date instanceof Date &&
        dayjs(date).isAfter(dayjs().subtract(1, 'years').toDate())) ||
      'Must not be before 1 year ago'
  };

  const validateStartDateConflict = {
    startDateConflict: (date: any) => {
      if (!date || !(date instanceof Date)) {
        return true;
      }
      const regimens = formMethods.getValues(
        'regimens'
      ) as MedicationRegimenForm[];
      let hasConflict = false;
      regimens.reduce<Map<string, any>>((accum, regimen) => {
        if (!(regimen.frequency!.dtstart instanceof Date)) {
          return accum;
        }
        const formattedDate = dayjs(regimen.frequency!.dtstart!).format(
          'MM/DD/YYYY'
        );
        if (accum.get(formattedDate)) {
          hasConflict = true;
        }
        accum.set(formattedDate, true);
        return accum;
      }, new Map<string, any>());
      return !hasConflict || 'Start date conflicts with another regimen';
    }
  };

  const startAndEndDateFields = (
    <>
      <Grid xs={12} md={6}>
        <ControlledDatePicker
          control={formMethods.control}
          label="Start date"
          minDate={dayjs().subtract(1, 'years').toDate()}
          name={`regimens.${index}.frequency.dtstart`}
          disabled={startDatePickerDisabled}
          rules={{
            required: true,
            validate: {
              ...(FeatureFlagService.isEnabled(
                ExacareFeature.MEDICATION_TASK_REGIMENS
              )
                ? validateStartDateConflict
                : {}),
              ...validateMinDate
            }
          }}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <ControlledDatePicker
          control={formMethods.control}
          label="End date (optional)"
          name={`regimens.${index}.frequency.until`}
          tooltip="The schedule runs indefinitely if left empty"
          disabled={disabled}
          rules={{
            validate: {
              pastDate: (date) =>
                !date ||
                (date instanceof Date &&
                  differenceInCalendarDays(date, new Date()) >= 0) ||
                'Must not be a past date',
              beforeStartDate: (date) =>
                !date ||
                (date instanceof Date &&
                  differenceInCalendarDays(
                    formMethods.getValues(
                      `regimens.${index}.frequency.dtstart`
                    ),
                    date
                  ) <= 0) ||
                'Must not be before start date'
            }
          }}
          disablePast
        />
      </Grid>
    </>
  );

  return (
    <>
      <StyledAccordion
        expanded={expanded}
        onChange={handleSetExpanded}
        sx={{ width: '100%' }}>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            height: 60,
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              aligns: 'center',
              justifyContent: 'space-between',
              '& > span': {
                fontWeight: 500
              }
            }
          }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Regimen {formattedRegimenDate}
          </span>
          {fieldArrayFormMethods.fields.length > 1 && !disabled && (
            <IconButton onClick={handleDeleteRegimen}>
              <Delete />
            </IconButton>
          )}
        </StyledAccordionSummary>
        <StyledAccordionDetails
          sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <ControlledSelectPrimitive
                control={formMethods.control}
                name={`regimens.${index}.frequency.freq`}
                label="Frequency"
                options={
                  FeatureFlagService.isEnabled(
                    ExacareFeature.EHR_MEDICATIONS_PATTERNED_RECURRENCE
                  )
                    ? medicationFrequencyOptions
                    : medicationFrequencyOptions.filter(
                        (option) =>
                          option.value !== hourlyOrMinutelyFrequencyOption.value
                      )
                }
                optionIdKey="value"
                optionLabelKey="label"
                disabled={disabled}
                rules={{
                  required: true,
                  ...(FeatureFlagService.isEnabled(
                    ExacareFeature.MEDICATION_TASK_REGIMENS
                  )
                    ? {
                        validate: {
                          conflict: () => {
                            const regimens = formMethods.getValues(
                              'regimens'
                            ) as MedicationRegimenForm[];
                            const hasConflict =
                              regimens.some(
                                (regimen) => regimen.frequency?.freq === 'prn'
                              ) && regimens.length > 1;
                            return (
                              !hasConflict ||
                              'PRN medications can only have one regimen'
                            );
                          }
                        }
                      }
                    : {})
                }}
                onChange={(freq) => {
                  handleChangeFreq(freq);
                }}
              />
            </Grid>
            {frequency === 'custom' ? (
              <>
                {startAndEndDateFields}
                <CustomFrequency
                  formMethods={formMethods}
                  isMedicationsDrawer={true}
                  schedulePath={`regimens.${index}.schedule`}
                  frequencyPath={`regimens.${index}.frequency`}
                  disabled={disabled}
                />
              </>
            ) : (
              <>
                {frequency === RRule.DAILY && (
                  <Grid xs={12}>
                    <ControlledSelectPrimitive
                      control={formMethods.control}
                      name={`regimens.${index}.frequency.interval`}
                      label="Pattern"
                      options={dailyIntervalOptions}
                      optionIdKey="value"
                      optionLabelKey="label"
                      rules={{ required: true }}
                      disabled={disabled}
                    />
                  </Grid>
                )}
                {frequency === RRule.WEEKLY && (
                  <>
                    <Grid xs={12} md={6}>
                      <ControlledSelectPrimitive
                        control={formMethods.control}
                        name={`regimens.${index}.frequency.interval`}
                        label="Recur every"
                        options={weeklyIntervalOptions}
                        optionIdKey="value"
                        optionLabelKey="label"
                        rules={{ required: true }}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <ControlledSelectPrimitive
                        control={formMethods.control}
                        name={`regimens.${index}.frequency.byweekday`}
                        label="Day"
                        options={weeklyDayOptions}
                        optionIdKey="value"
                        optionLabelKey="label"
                        rules={{ required: true }}
                        disabled={disabled}
                      />
                    </Grid>
                  </>
                )}
                {frequency === RRule.MONTHLY && (
                  <Grid xs={12}>
                    <ControlledSelectPrimitive
                      control={formMethods.control}
                      name={`regimens.${index}.frequency.bymonthday`}
                      label="Day of the month"
                      options={monthlyIntervalOptions}
                      optionIdKey="value"
                      optionLabelKey="label"
                      rules={{ required: true }}
                      disabled={disabled}
                    />
                  </Grid>
                )}
                {frequency === 'hourly-or-minutely' && (
                  <>
                    <Grid xs={12} md={4}>
                      <ControlledSelectPrimitive
                        control={formMethods.control}
                        name={`regimens.${index}.frequency.interval`}
                        label="Every"
                        options={everyHourlyOrMinutelyOptions}
                        optionIdKey="value"
                        optionLabelKey="label"
                        rules={{ required: true }}
                        disabled={disabled}
                      />
                    </Grid>
                  </>
                )}
                {frequency === 'hourly-or-minutely' && (
                  <PeriodTimeInput
                    formMethods={formMethods}
                    gridBreakpoints={{
                      from: 4,
                      to: 4
                    }}
                    fromFieldName={`regimens.${index}.frequency.execution_window_start_time`}
                    toFieldName={`regimens.${index}.frequency.execution_window_end_time`}
                    fromFieldLabel="Start Time"
                    toFieldLabel="End Time"
                  />
                )}
                {frequency &&
                  frequency !== 'one-time-task' &&
                  frequency !== 'informational' &&
                  startAndEndDateFields}
                {frequency !== 'prn' &&
                  frequency !== 'informational' &&
                  frequency !== 'twice-a-day' &&
                  frequency !== 'three-times-a-day' &&
                  frequency !== 'four-times-a-day' &&
                  frequency !== 'five-times-a-day' &&
                  frequency !== 'hourly-or-minutely' && (
                    <>
                      <TypeAndTimeInput
                        fieldNames={{
                          startTime: `regimens.${index}.frequency.execution_window_start_time`,
                          endTime: `regimens.${index}.frequency.execution_window_end_time`,
                          type: `regimens.${index}.frequency.type`,
                          partOfDay: `regimens.${index}.frequency.partOfDay`,
                          numberOfDoseUnits: `regimens.${index}.frequency.number_of_dose_units`
                        }}
                        gridBreakpoints={{
                          from: 4,
                          to: 4
                        }}
                        formMethods={formMethods}
                        disabled={disabled}
                      />
                    </>
                  )}
                {frequency === 'one-time-task' && (
                  <Grid xs={12}>
                    <ControlledDatePicker
                      control={formMethods.control}
                      label="Date of Task"
                      name={`regimens.${index}.frequency.dtstart`}
                      rules={{
                        required: true,
                        validate: {
                          ...(FeatureFlagService.isEnabled(
                            ExacareFeature.MEDICATION_TASK_REGIMENS
                          )
                            ? validateStartDateConflict
                            : {})
                        }
                      }}
                      disablePast
                      disabled={disabled}
                    />
                  </Grid>
                )}
                {(frequency === 'twice-a-day' ||
                  frequency === 'three-times-a-day' ||
                  frequency === 'four-times-a-day' ||
                  frequency === 'five-times-a-day') && (
                  <MultipleTimesADay
                    formMethods={formMethods}
                    frequencyPath={`regimens.${index}.frequency`}
                    schedulePath={`regimens.${index}.schedule`}
                    isMedicationsDrawer
                    disabled={disabled}
                  />
                )}
                {frequency === 'prn' && (
                  <NumberOfDoseUnits
                    formMethods={formMethods}
                    name={`regimens.${index}.frequency.number_of_dose_units`}
                  />
                )}
                {(frequency === RRule.DAILY ||
                  frequency === RRule.WEEKLY ||
                  frequency === RRule.MONTHLY ||
                  frequency === 'one-time-task' ||
                  frequency === 'hourly-or-minutely') && (
                  <ScheduleDoseForm
                    formMethods={formMethods}
                    frequencyPath={`regimens.${index}.frequency`}
                    slidingScaleDoseActive={slidingScaleDoseRange !== undefined}
                    disabled={disabled}
                  />
                )}
              </>
            )}
          </Grid>
          {frequency === 'prn' && (
            <>
              <PrnLimitationsForm
                formMethods={formMethods as any}
                numberOfDoseUnits={numberOfDoseUnits}
              />
              <PrnFollowupTaskForm
                formMethods={formMethods}
                type={type}
                disabled={disabled}
              />
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </>
  );
};
